.addCourseBtn {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 50px 100px;
	width: 100%;
	background: #ffffff;
	border: 1px dashed #a6a6a6;
	box-sizing: border-box;
	border-radius: 15px;
	flex: none;
	order: 2;
	flex-grow: 0;
	margin: 20px 0px;
}

.addCourseBtn:hover {
	border: 1px dashed #f97b26;
	cursor: pointer;
}

.addCourseBtn:hover .AddCourseWrp {
	color: #f97b26;
	cursor: pointer;
}

.addCourseBtn:hover i.fa.fa-plus-circle.fa-2x {
	color: #f97b26;
	cursor: pointer;
}

p.add-ins-text {
	margin-bottom: 0;
	margin-left: 5px;
}

p.AddCourseWrp {
	display: flex;
	width: 100%;
	align-items: center;
	margin-bottom: 0;
	justify-content: center;
}

.AddCourseWrp i.fa.fa-plus-circle.fa-2x {
	color: #a6a6a6;
	font-size: 22px;
}

.courseBox {
	display: flex;
	flex-direction: column;
	padding: 44px 50px;
	width: 100%;
	background: #efefef;
	border-radius: 15px;
	margin: 47px 0px;
	background: #ffffff;
	box-shadow: 4px 4px 30px rgb(0 0 0 / 7%);
	border-radius: 15px;
}

.courseBox.deactivecourseBox {
	background: #efefef;
	box-shadow: none;
	opacity: 0.8;
}

.courseBox .btnWrpp {
	margin: 24px 0 40px 0;
}

.course-container .addCourseBox {
	align-items: flex-start;
}

.course-container .addCourseBox .MuiButton-root {
	height: 36px;
}

.seeAllbtn a {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 18px;
	color: #012c63;
	text-decoration: none;
	cursor: pointer;
}

.seeAllbtn p {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #000000;
}

.seeAllbtn p a {
	font-size: 16px;
	line-height: 18px;
	color: #012c63;
	margin-left: 10px;
	text-decoration: none;
	cursor: pointer;
}

.seeAllbtn a:hover {
	text-decoration: none;
}

.courseBox label {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #000000;
	margin-bottom: 0;
}

.containerMain.courseDetail .courseBox .btnWrpp button.MuiButton-root {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 30px;
	text-align: center;
}

.btnWrpp {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.btnWrpp button {
	margin-left: 0;
	margin-right: 0;
}

.courseBox .css-17i7n9v {
	display: flex;
	flex-direction: column;
}

.courseBox .MuiBox-root .MuiFormControl-root {
	margin: 10px 0;
	width: 100%;
}

.MuiOutlinedInput-root div#demo-simple-select {
	width: 230px;
	padding: 12px;
	height: 20px;
}

.MuiOutlinedInput-root div#demo-simple-selec {
	width: 100%;
	padding: 12px;
	height: 20px;
}

.courseBox button.MuiButton-root.MuiButton-contained {
	margin-left: 0px;
}

.containerMain {
	display: inline-block;
	width: 100%;
	padding: 20px;
}

/* .containerMain.adjust-nav {
    flex: 1 1 auto;
    width: auto;
} */

/****************** Top nav bar css ****************************/
.topnavbar {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	align-items: center;
	/* position: relative; */
	position: sticky;
	top: 0;
	padding: 0 20px;
	background: #ffffff;
	height: 87px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	margin: 0 auto;
	width: 100%;
}

.profile_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.topBar-logo {
	padding: 10px 10px;

	/* position: absolute; */
	/* min-width: 195px;
    max-width: 220px; */
	min-width: 210px;
	max-width: 235px;
	height: 62px;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-sizing: border-box;
	border-radius: 71px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.center-badge {
	padding: 6px 10px;
	height: 29px;
	background: #fafafa;
	border-radius: 37px;
	margin: 6px 10px;
	display: inline-block;
}

/* .centerlist{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: baseline;
} */
.bellicon {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 23px;

	position: absolute;
	width: 52px;
	height: 52px;
	left: 0px;
	top: 5px;

	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-sizing: border-box;
	border-radius: 8px;
	background-image: url(./../../assets/img/bell.svg);
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
}

.IconsWrp {
	display: flex;
	align-items: center;
	float: right;
}

.topnavbar .bellicon {
	position: inherit;
	margin-right: 20px;
}

.badgesWrapOuter {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.badgesWrap {
	display: inline-block;
	/* width: 200px; */
}

.ImgText {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.centerlist {
	width: calc(100% - 265px);
	float: left;
	text-align: center;
	margin: 0 auto;
}

.ImgInner {
	margin-right: 10px;
}

.ImgText h4 {
	margin: 0;
	font-size: 16px;
}

.badgesWrap .center-badge:first-child {
	margin-left: 0px;
}

.textWrp p {
	margin-bottom: 0;
	font-size: 14px;
}

button.logoutBtn {
	background: #f8fbff;
	border-radius: 6px;
	border: 1px solid #f8fbff;
	color: #012c63;
	padding: 5px 15px;
	font-weight: 600;
	font-size: 12px;
	line-height: 27px;
	letter-spacing: 0.02em;
}

button.logoutBtn:hover {
	background: #f8fbff;
	border-radius: 6px;
	border: 1px solid #f8fbff;
	color: #012c63;
	padding: 5px 15px;
}

button.customSideNavBtn {
	font-size: 14px;
}

.customSideNavBtn li span {
	font-size: 14px;
}

label.customLabel {
	font-size: 14px;
	margin: -4px;
}

.customLabel label {
	font-size: 14px;
	margin: -4px;
}

.customLabel p {
	font-size: 11px;
	line-height: normal;
	margin-left: 0px;
}

.customLabel .MuiSelect-select {
	font-size: 12px;
}

.customLabel .MuiOutlinedInput-input {
	font-size: 12px;
}

.MuiSwitch-colorError {
	color: #d32f2f;
}

.MuiSwitch-colorError + .MuiSwitch-track {
	background-color: #d32f2f;
}

/*
.MuiSwitch-colorSuccess {
    color: #2e7d32;
}
.MuiSwitch-colorSuccess + .MuiSwitch-track{
    background-color: #2e7d32;
} */

.clickActivate span.MuiSwitch-colorError.MuiSwitch-colorError {
	color: #bc0000;
}

label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.switchLabel {
	display: flex !important;
	flex-direction: row-reverse !important;
	margin-right: 0;
	margin-left: 0;
}

/**************** upload form css *******************/
.filesBox {
	align-items: center;
	padding: 50px;
	max-width: 1063px;
	width: 100%;
	height: 312px;
	background: #ffffff;
	border: 1px dashed #000000;
	box-sizing: border-box;
	border-radius: 10px;
	margin: 45px 0px;
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
}

.filesBox .inputFile {
	margin-bottom: 20px;
	width: 45px;
	height: 54px;
	border-radius: 6px;
	position: relative;
	text-align: center;
	cursor: pointer;
	background-image: url(../../assets/img/fileUpload.svg);
	background-repeat: no-repeat;
	background-position: center;
}

.btnWrp {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
}

.btnWrp button.customSideNavBtn {
	font-size: 20px;
	background: #012c63;
	border-radius: 9px;
	border: 1px solid #012c63;
	color: #fff;
	padding: 12px 50px;
}

.filesBox input.fileup {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
}

.fileUploadHelperText {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.02em;
	color: #505050;
}
.fileUploadHelperText.hub {
	margin-top: 50px;
}

/* .inputFile img {
    position: absolute;
    top: 14px;
    left: 12px;
    width: 18px;
    cursor: pointer;
    z-index: 2;
} */

.inputFile input.fileup {
	opacity: 0;
	width: 100%;
	height: 45px;
	position: relative;
	z-index: 1;
	cursor: pointer;
}

input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
	/* chromes and blink button */
	cursor: pointer;
}

.modal-backdrop {
	z-index: 9999;
}

.modal {
	z-index: 999999991 !important;
}

.containerMain.courseDetail {
	background-color: #e5e5e5;
}

.upload_form_container.is-mobile .filesBox {
	width: 100%;
}

.search_student_course_details {
	white-space: nowrap;
	width: 160px;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media screen and (max-width: 480px) {
	.containerMain {
		padding: 8px;
	}
	.search_student_course_details {
		width: 120px;
	}
}

@media screen and (max-width: 360px) {
	.search_student_course_details {
		width: 100px;
	}
}

@media screen and (max-width: 768px) {
	.fileUploadHelperText.hub {
		margin-top: 0px !important;
		line-height: 20px;
	}
}
