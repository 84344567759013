body {
    font-family: 'Montserrat', sans-serif;
}
.installCreateWrap {
    margin-top: 30px;
    margin-bottom: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 15%);
    border-radius: 24px;
    padding-bottom: 70px;
}

.add-hostel-btn:hover{
    cursor: pointer;
}
.installCreateWrap .bgFull {
    padding: 20px 0;
    background: #f9fbff;
    margin-bottom: 40px;
    padding-left: 50px;
}
.installCreateWrap .mb-3 {
    margin-bottom: 10px;
}
.installCreateWrap .mb-4 {
    margin-bottom: 35px;
}
.installCreateWrap .mb-5 {
    margin-bottom: 15px;
}
.headingWrap {
    background: #fef9f5;
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding-left: 50px;
}
.headingWrap h2 {
    background: #fef9f5;
    margin: 25px 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
}
.bgFull b {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #000000;
}
.installCreateWrap button.btn.btn-success {
    background: #012c63;
    border-color: #012c63;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding: 8px 22px;
}
.bgFull p {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #727272;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 15px;
    word-break: break-all;
}
.payFields label {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #101010;
}
.payFields p b {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #0C0D0C;
}
/* .payFieldsOuter {
}  */
.payFields input.form-control {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #080707;
    height: 38px;
    border: 1px solid #858585;
    box-sizing: border-box;
    border-radius: 6px;
}
.input-group.discountWrpField {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #080707;
    height: 38px;
    border: 1px solid #858585;
    box-sizing: border-box;
    border-radius: 6px;
}
.input-group.discountWrpField span.input-group-addon {
    background: transparent;
    border: none;
    padding-right: 0px;
}
.input-group.addNumber input.form-control {
    padding: 6px 3px;
    text-align: center;
}
input.form-control.discountWrp {
    border: none;
    box-shadow: none;
}
.input-group.addNumber {
    display: flex;
    align-items: center;
}
.addNumber input.form-control, .addNumber button.btn {
    height: 35px;
    width: 35px;
    border: none;
    box-shadow: none;
}
.addNumber button.btn:focus,
.addNumber button.btn:hover {
    outline: none;
}
.addNumber button.btn {
    font-size: 24px;
    line-height: 20px;
    border-radius: 6px;
    padding: 5px 8px 12px 8px;
}
.installCreateWrap  .nav-tabs>li>a {
    background: #F9F9F9;
    margin-right: 18px;
    letter-spacing: 0.02em;
    color: #000000;
    border-radius: 8px 8px 0 0;
}
.installCreateWrap .nav-tabs>li.active>a,
.installCreateWrap .nav-tabs>li.active>a:focus,
.installCreateWrap .nav-tabs>li.active>a:hover {
    border: 1px solid #012c63;
    border-bottom-color: transparent;
}
.installCreateWrap .nav-tabs {
    border-bottom: 1px solid transparent;
}
.installCreateWrap .nav-tabs>li {
    margin-bottom: 0px;
}
.installCreateWrap .nav-tabs>li.active {
    top: 1px;
}
.courseDetailWrap {
    padding: 30px 50px;
}

@media screen and (max-width: 900px) {
    .courseDetailWrap {
        padding: 10px 20px;
    }
}

ul.courseDetail {
    list-style: none;
    padding-left: 0;
    display: flex;
}
ul.courseDetail li{
    margin-right: 25px;
    word-break: break-all;
}
.installmentWrap .table tbody td::after {
    content: '';
    position: absolute;
    right: 0;
    top: 6px;
    height: 25px;
    width: 2px;
    background-color: #EBEBEB;
}
.installmentWrap .table tbody td {
    border-top: 1px solid #000000;
    padding: 10px 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: normal;
    color: #727272;
    text-align: center;
    position: relative;
    border: none;
}
.installmentWrap  .table {
    border-collapse: separate;
    border-spacing: 2px;
}
.installmentWrap .table tbody td b {
    color: #000000;
}
.installmentWrap table.border.table {
    border: 1px solid #000000;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    border-radius:4px;
}
.installmentWrap .table tbody td:last-child::after{
    display: none;
}
.installmentList .table thead th {
    background: #F9F9F9;
    border-bottom: transparent !important;
    padding: 15px !important;
}
.installmentList td {
    padding: 20px 15px !important;
}
.installmentList table.table {
    border-collapse: separate;
    border-spacing: 0 0.5em;
}
.installmentList table tr td {
    border: 1px solid #BCBCBC;
    border-left: none;
    border-right: none;
    color: #505050;
    vertical-align: middle;
}
.installmentList table tr td:first-child {
    border-left: 1px solid #BCBCBC;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}
.installmentList table tr td:last-child {
    border-right: 1px solid #BCBCBC;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}
.sapceBtn {
    display: flex;
    justify-content: space-between;
}
button.payNowBtn {
    background: #012C63;
    border-radius: 9px;
    color: #fff;
    border: 1px solid #012C63;
    padding: 16px 17px;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.02em;
}
button.payhistBtn {
    background: #F8FBFF;
    border-radius: 9px;
    border: 1px solid #F8FBFF;
    color: #012C63;
    padding: 5px 15px;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.02em;
    height: 75px
}
.tab-pane.active {
    border: 1px solid #012c63;
    position: relative;
    top: -1px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
ul li.active a {
    position: relative;
    z-index: 1;
    bottom: -1px;
}
.paymentPopup.modal {
    z-index: 9999991;
}
/* .modal-backdrop {
    z-index: 999999;
} */
/* Popup Css start */
h4.modal-title {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #000000;
    font-weight: 600;
}
.paymentPopup button.close {
    font-size: 30px;
    color: #000;
    opacity: 1;
    font-weight: 400;
    position: absolute;
    right: 10px;
    top: 10px
}
.paymentPopup .contain {
    display: inline-block;
    position: relative;
    padding: 6px 10px 6px 25px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #0C0D0C;
    background: #FAFAFA;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 20px;
}
label.contain.active {
    background: #FFE8D7;
    border: 1px solid #E97A26;
}
/* .paymentPopup label.contain.cash {
    background: #FAFAFA;
    border: 1px solid #DFDFDF;
} */

  /* Hide the browser's default radio button */
  .paymentPopup .contain input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .paymentPopup .checkmark {
    position: absolute;
    top: 8px;
    left: 6px;
    height: 14px;
    width: 14px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #000;
}

  /* On mouse-over, add a grey background color */
  .paymentPopup .contain:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .paymentPopup .contain input:checked ~ .checkmark {
    background-color: transparent;
    border: 1px solid #000;
}

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .paymentPopup .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .paymentPopup .contain input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .paymentPopup .contain .checkmark:after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: black;
}
.paymentPopup .modal-footer {
    text-align: left;
    border-top: none;
    padding: 15px 0 0 0;
}
.paymentPopup .modal-header {
    border-bottom: none;
}
input.enterAmt {
    width: 190px;
}
.paymentPopup .modal-content {
    padding: 50px;
}
.paymentPopup .modal-content .modal-header {
    padding: 0 0 15px 0px;
}
.paymentPopup .modal-content .modal-body {
    padding: 15px 0 0 0;
    color: #101010;
}
.paymentPopup button.btn.btn-default {
    background: #012C63;
    border-radius: 9px;
    color: #fff;
    border-color: #012C63;
    padding: 6px 20px;
    font-size: 18px;
    font-weight: 600;
}
/* .editInstallmentWrap {
    padding: 0 85px;
} */
input.amt {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    border: none;
    padding: 14px;
}
input.amt:focus {
    outline: none;
    border: none;
}

.custom-asterisk{
    color: #ff0000;
    font-size: 12px;
    position: relative;
    top: -6px;
    left: 2px;
}
.installmentWrap table.border.table.PayableTable td:last-child {
    text-align: end;
}

.installmentWrap table.border.table.PayableTable td {
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
}
.paymentPopup .nav-tabs>li.active>a {
    border-color: #012c63;
    border-bottom: none;
}
.bg-success {
    background-color: #E8FFE0;
    padding: 3px 8px;
    text-align: center;
    border-radius: 20px;
    color: #35CB00;

}
.bg-redeemed {
    background-color: #F6EADC;
    padding: 3px 8px;
    text-align: center;
    border-radius: 20px;
    color: #A58F76;
}

.bg-info {
    background-color: #E0E5FF;
    padding: 5px;
    text-align: center;
    border-radius: 20px;
    color:#012C63;
}

.bg-danger {
    background-color: #FFE0E0;
    padding: 5px;
    text-align: center;
    border-radius: 20px;
    color:#C80000;
}
.paymentPopup  button.ViewBtn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #847C7C;
    border: 1px solid #847C7C;
    box-sizing: border-box;
    border-radius: 8px;
}
.tableWrap .btnsWrap button {
    width: 150px;
    height: 40px;
}
.d-flex.deleteText {
    flex-direction: column;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
}

.amount-alert {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    margin-top: -20px;
  }
  .amount-alert p {
    color: #ff0000;
    font-weight: 600;
    font-size: 1.1em;
    padding: 8px 0px 10px 6px;
    margin: 0px;
    /* padding-top: 0; */
  }

@media (min-width: 1200px) {
.paymentPopup .modal-lg {
    width: 900px;
}
}
/* Popup Css end */
.bankDetail h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #0C0D0C;
    margin: 0;
}
.bankDetail label {
    font-weight: 500;
    line-height: 14px;
}
button.confirmBtn {
    background: #012c63;
    border: 1px solid #012c63;
    letter-spacing: 0.4px;
    padding: 16px 37px;
    color: #fff;
    border-radius: 6px;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
}
.btnWrap {
    display: flex;
}
.btnWrap button.camertaBtn {
    margin-right: 15px;
}
.upbtn img {
    position: absolute;
    top: 14px;
    left: 12px;
    width: 18px;
    cursor: pointer;
    z-index: 2;
}
.btnWrap button.camertaBtn {
    margin-right: 15px;
    background: #fff;
    border: 1px solid #858585;
    border-radius: 6px;
    width: 45px;
}
.upbtn input.fileup {
    opacity: 0;
    width: 100%;
    height: 45px;
    position: relative;
    z-index: 1;
    cursor: pointer;

}
.bankDetail .span {
    font-size: 12px;
    color: #101010;
    opacity: 0.3;
}
.camertaBtn img {
    width: 18px;
}
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer;
}
.upbtn {
    width: 45px;
    height: 45px;
    border: 1px solid #858585;
    border-radius: 6px;
    position: relative;
    text-align: center;
    cursor: pointer;
    background-image: url(../../assets/img/fileup.png);
    background-repeat: no-repeat;
    background-position: center;
}
button.btn.minusBtn span {
    position: relative;
    bottom: 2px;
}
.bankDetail .select, .bankDetail .input {
    height: 48px;
    border-radius: 6px;
    border: 1px solid #858585;
    padding: 8px 14px;
    font-weight: 500;
    font-size: 24px;
    line-height: 18px;
}
.bankDetail .form-group {
    margin-bottom: 35px;
}
.bankDetail h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #686868;
}
.bankDetail .form-group.upCheque {
    margin-bottom: 20px;
}
.addInstallmentWrp p i.fa.fa-plus-circle.fa-2x {
    margin-right: 10px;
}
.addInstallmentWrp p {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    color: #A6A6A6;
}
.installmentList .addInstallmentWrpOuter td {
    padding: 0px !important;
    border: none !important;
}
.installmentList .addInstallmentWrpOuter td .addInstallmentWrp {
    padding: 14px 15px !important;
    border-style: dashed;
    border-width: medium;
    border: 2px dashed #BCBCBC;
    margin-top: 25px;
    cursor: pointer;
}
.installmentList .addInstallmentWrpOuter td .addInstallmentWrp:hover{
    border-color:#E97A25
}
.installmentList .addInstallmentWrpOuter td .addInstallmentWrp:focus{
    border-color:#E97A25
}
.installmentList .addInstallmentWrpOuter td .addInstallmentWrp:hover .fa-plus-circle{
    color:#E97A25
}
.installmentList .addInstallmentWrpOuter td .addInstallmentWrp:focus .fa-plus-circle{
    color:#E97A25
}

.installmentList .addInstallmentWrpOuter td .addInstallmentWrp:hover .add-ins-text{
    color:#E97A25
}
.installmentList .addInstallmentWrpOuter td .addInstallmentWrp:focus .add-ins-text{
    color:#E97A25
}

.editInstallmentWrap button.btn.btn-primary.btn-lg:focus {
    outline: none;
}
.installmentPaymentt {
    padding-left: 50px;
}
.installmentPlanWrap h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.02em;
    color: #000000;
    text-align: center;
}
.installmentPlanWrap h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #012C63;
    text-align: center;
}
.tableWrap tfoot {
    border: 1px solid #ddd;
}
.installmentPlanWrap .modal-body {
    padding: 60px;
}
.installmentPlanWrap .tableWrap thead tr th,
.installmentPlanWrap .tableWrap tbody tr td,
.installmentPlanWrap .tableWrap tfoot td {
    padding: 15px;
}
.installmentPlanWrap .tableWrap thead tr th {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #4E4E4E;
}
.installmentPlanWrap .tableWrap tbody tr td {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #4E4E4E;
}
.installmentPlanWrap .tableWrap tfoot td {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #000000;
}
.installmentPlanWrap .tableWrap thead tr th {
    background: #FAFAFA;
    border-bottom: none !important;
}
.d-flex.btnsWrap {
    display: flex;
    justify-content: center;
}
button.btn.modal-button.cancelBtn {
    background: #FFFFFF;
    border: 1px solid #012C63;
    box-sizing: border-box;
    border-radius: 9px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #012C63;
    padding: 8px 24px;
}
button.btn.btn-primary.modal-button.confirmBtn2:focus,
button.btn.modal-button.cancelBtn:focus {
    outline: none;
    box-shadow: none;
}
button.btn.btn-primary.modal-button.confirmBtn2 {
    background: #012C63;
    border-radius: 9px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-left: 20px;
    border-color: #012C63;
    width: auto;
}
button.addbtn2 {
    position: absolute;
    right: -40px;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 8px;
    color: #E1E1E1;
    background: #fff;
    transform: translateY(-50%);
    top: 50%;
    transition: 0.5s all ease;
}
.installmentList tr {
    position: relative;
}
.editInstallmentWrap .installCreateWrap .tabWrap {
    padding-right: 60px;
    padding-left: 20px;
}
button.addbtn2:hover {
    color: #ff0000;
    border-color: #ff0000;
}
.tabWrap a.active {
    border: 1px solid #847C7C;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 5px 12px;
    margin: 20px 0px 10px 0;
    color: #847C7C;
    display: inline-block;
    text-decoration: none;
}
.LoadPage p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #0C0D0C;
    margin-top: 40px;
}
.LoadPage p:first-child {
    margin-top: 0px;
}
.installCreateWrap .payFieldsOuter.tabWrap button.btn.btn-success {
    background: #012c63;
    border-color: #012c63;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding: 8px 22px;
    width: 100%;
}
.payFieldsOuter.tabWrap .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}
.InnerPopup {
    max-width: 350px;
    padding: 15px 15px;
    margin: 0 auto;
    width: 100%;
}
.currencyWrap span {
    position: relative;
    left: 25px;
}
p.currencyWrap {
    position: relative;
    margin-bottom: 0;
}
.currencyWrap input.amt {
    padding-left: 30px;
}
.successWrp {
    position: relative;
    bottom: 100px;
}
.ErrorWrap.tabWrap.paymentSuccess .successWrp {
    bottom: 20px;
}
.ErrorWrap.tabWrap.paymentSuccess img {
    max-width: 350px;

}
.duedate {
    display: none;
    font-size: 13px;
    font-weight: 600;
}
span.duedate::before {
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    background: #FFE0E0;
    top: -24%;
    transform: translateY(-50%);
    left: 40%;
    transform: rotate(45deg);
}
p i:hover+span {
    display: block;
    position: absolute;
    background: #FFE0E0;
    color: #AB0000;
    padding: 7px;
    border-radius: 4px;
    top: 45px;
    left: -32px;
    z-index: 1;
}
p.installRed, p.installGreen {
    display: inline-table;
}
.installGreen i:hover+span, .installGreen span.duedate::before {
    color: #155724;
    background-color: #d4edda;
}

@media (min-width: 1199px) {
.installmentPlanWrap .modal-dialog {
    width: 1090px;
}
}



@media (min-width: 768px) {
.payFieldsOuter .container,
.installCreateWrap .container {
    width: 100%;
}
}
@media (min-width: 992px) {
    .installCreateWrap .container {
        width: 100%;
    }
}
@media (min-width: 1200px) {
.installCreateWrap .container {
    width: 100%;
}
.installCreateWrap .tabWrap {
    max-width: 90%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
}

@media (min-width: 1400px) {
    .editInstallmentWrap .installCreateWrap .container {
        width: 100%;
    }
    .editInstallmentWrap .installCreateWrap .tabWrap {
        max-width: 1320px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .editInstallmentWrap  .courseDetailWrap {
        padding-right: 0px;
        padding-left: 0px;
    }
    }

    @media only screen and (max-width: 1600px) {
    .headingWrap, .installCreateWrap .bgFull,
    .installmentPaymentt {
        padding-left: 50px !important;
    }
}
@media only screen and (max-width: 1550px) {
    .headingWrap, .installCreateWrap .bgFull, .payFieldsOuter {
        padding-left: 0px;
    }

}

    @media only screen and (max-width: 1366px) {
        /* .editInstallmentWrap {
            padding: 0 50px;
        } */
        .editInstallmentWrap .courseDetailWrap {
            padding: 30px 0px;
        }
    }

    @media only screen and (max-width: 767px) {
        .con1 {
            padding: 17px 15px;
        }
        .nav_heading img {
            max-width: 130px;
            width: 100%;
        }
        .bell_icon {
            margin-right: 0px;
        }
        .con2 img {
            width: 25px;
        }
        .hamburger {
            cursor: pointer;
            width: 30px;
        }
        .line {
            margin-bottom: 5px;
            width: 100%;
            height: 3px;
            background-color: #00278a;
        }
        .headingWrap, .installCreateWrap .bgFull, .installmentPaymentt {
            padding-left: 20px !important;
        }
        /* .installmentPaymentt, .installCreateWrap .bgFull{
            padding-left: 20px !important;

        } */
        .installmentList {
            padding-bottom: 20px;
        }
        .installmentList table.table {
            width: 100%;
            max-width: 1024px;
        }
        .installmentList .table-responsive {
            border: none;
        }
        .editInstallmentWrap .installCreateWrap .tabWrap {
            padding-right: 0px;
            padding-left: 0px;
        }
        .editInstallmentWrap {
            padding: 0px;
        }
        button.addbtn2 {
            right: -30px;
        }
        .installmentPlanWrap .modal-body {
            padding: 10px;
        }
        .tabWrap.paymentSuccess img {
            width: 100%;
        }
    }
    @media only screen and (max-width: 479px) {
        .successWrp {
            bottom: 50px;
        }
    }

    .btn.disabled:hover {
        text-decoration: none;
        color: #fff;
        pointer-events: none;
    }

.courseDetailWrap-inner {
    display: flex;
    margin-bottom: 2rem;
}

.courseDetailWrap-inner-1 {
    display: flex;
    margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
    .courseDetailWrap-inner {
        flex-direction: column;
        margin-bottom: 1.5rem;
    }

    .courseDetailWrap-inner-1 {
        flex-direction: column;
        margin-bottom: 5px;
    }
}