.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	/* background-color: #282c34; */
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

html {
	height: 100%;
}

body {
	height: 100%;
	background-color: #e5e5e5 !important;
	/* background-color: #F9F8F8 !important; */
}

.cursor-pointer {
	cursor: pointer !important;
}

.d-flex {
	display: flex !important;
}

.d-column {
	display: flex !important;
	flex-direction: column !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-flex-start {
	justify-content: flex-start !important;
}

.justify-content-flex-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.d-flex-wrap {
	display: flex !important;
	flex-wrap: wrap !important;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.processing_gif {
	width: 400px;
}

@media only screen and (max-width: 990px) {
	.processing_gif {
		width: 300px;
	}

	.redirection_page_container {
		margin: 28px 20px !important;
	}

	.course_data_wrapper {
		padding: 0.5rem 0;
	}
}

@media only screen and (max-width: 600px) {
	.payment_reversal_container {
		flex-direction: column !important;
		margin-top: -4rem !important;
		height: 100%;
	}

	.payment_reversal_container2 {
		flex-direction: column !important;
	}

	.confirm_cash_checkbox_div3 {
		padding-left: 0px !important;
	}

	.confirm_cash_fm_view {
		padding-left: 0px !important;
	}

	.payment_reversal_container_pending {
		margin-right: 0rem !important;
		margin-top: 1rem;
		width: 270px !important;
		height: 270px !important;
	}

	.assign_reviewer_container_pending {
		margin-right: 0rem !important;
		margin-top: 1rem;
		width: 270px !important;
		height: 180px !important;
	}

	.payment_reversal_container_history {
		margin-left: 0rem !important;
		margin-top: 2rem;
		width: 270px !important;
		height: 270px !important;
	}

	.course_add_container {
		padding: 10px !important;
	}

	.full_batch_list_footer {
		padding: 10px !important;
	}

	.course_add_data_container {
		padding: 10px 18px !important;
	}

	.course_data_wrapper {
		padding: 1rem 0 !important;
	}

	.add_course_division {
		margin-top: 1rem !important;
	}

	.course_list_container {
		padding: 15px !important;
	}

	.mpp_list_container {
		padding: 15px !important;
	}

	.course_divider1 {
		width: 100%;
		display: block !important;
		flex: none !important;
	}

	.cash-position_divider1 {
		width: 100%;
		display: block !important;
		flex: none !important;
	}

	.course_divider3 {
		display: block !important;
		flex: none !important;
	}

	.cash-position_divider3 {
		display: block !important;
		flex: none !important;
	}

	.course_divider2 {
		flex: 0.5 !important;
		min-width: 100px !important;
	}

	.course_list_header {
		display: block !important;
	}

	.course_search_wrapper {
		margin-top: 1.5rem;
		max-width: 100% !important;
	}

	.course_search_input {
		max-width: 100% !important;
		min-width: 100% !important;
		margin-left: -1.7rem;
	}

	.course_search_image {
		left: 1rem !important;
	}

	.chapter_subject_view_container {
		margin-top: 0 !important;
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}

	.batch_read_only_wrapper {
		margin-top: 0.5rem !important;
	}

	.main_subject_container {
		margin-top: 0 !important;
	}

	.subject_divider1 {
		display: block !important;
		flex: none !important;
		width: 100%;
	}

	.subject_divider2 {
		flex: 0.5 !important;
	}

	.subject_divider3 {
		flex: 0.5 !important;
	}

	.reversal_container_options {
		flex-direction: column;
		align-items: flex-end;
	}

	.reversal_reason_dropdown {
		max-width: 100% !important;
	}

	.initiate_reversal_dropdown {
		margin-top: 1rem;
	}

	.hub-container {
		margin-top: 15px;
		margin-left: 15px;
		margin-right: 15px;
	}
}

.payment_reversal_container_pending {
	width: 310px;
	background-color: #f3f9ff;
	height: 310px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 2rem;
	flex-direction: column;
	border-radius: 8px;
	cursor: pointer;
}

.assign_reviewer_container_pending {
	width: 31%;
	background-color: #f3f9ff;
	height: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 8px;
	cursor: pointer;
	min-width: 250px;
}

.payment_reversal_container_history {
	width: 310px;
	background-color: #f3f9ff;
	height: 310px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 2rem;
	flex-direction: column;
	border-radius: 8px;
	cursor: pointer;
}

.payment_reversal_container_pending:hover {
	background: #ffebdc;
	border: 1px solid #f97b26;
	box-shadow: 20px 24px 80px rgba(0, 0, 0, 0.1);
}

.assign_reviewer_container_pending:hover {
	background: #ffebdc;
	border: 1px solid #f97b26;
	box-shadow: 20px 24px 80px rgba(0, 0, 0, 0.1);
}

.payment_reversal_container_history:hover {
	background: #ffebdc;
	border: 1px solid #f97b26;
	box-shadow: 20px 24px 80px rgba(0, 0, 0, 0.1);
}

.payment_reversal_container {
	display: flex;
	justify-content: center;
	align-items: center;
	/* margin-top: 12rem; */
	flex-direction: row;
	height: calc(100vh - 150px);
}

.payment_reversal_container2 {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	height: calc(100vh - 150px);
}

.payment_reversal_container_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 28px;
	text-align: center;
	letter-spacing: 0.02em;
	color: #0b0b0c;
}

.reversal_detail_header {
	padding: 1.5rem;
	background-color: #ffffff;
	border-radius: 12px;
	display: flex;
	align-items: center;
}

.reversal_detail_header_student {
	padding: 1.5rem;
	background-color: #ffffff;
	border-radius: 12px;
	display: flex;
	justify-content: space-between;
	justify-content: flex-start;
	align-items: center;
}

.reversal_detail_header-lead {
	font-weight: 400;
	font-size: 14px;
	padding-right: 10px;
}

.reversal_detail_header-text {
	display: flex;
	align-items: center;
}

.reversal_detail_header-lead-text {
	font-weight: 500;
	font-size: 18px;
	line-height: 1;
}

.reversal_detail_header_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #000000;
}
.reversal_detail_header_text.width_150 {
	font-size: 22px;
	min-width: 180px;
	font-weight: 500;
}

.reversal_detail_card {
	background-color: #ffffff;
	margin-top: 2rem;
	border-radius: 12px;
	padding: 1.5rem;
}

.reversal_detail_card .reversal_request_user-info {
	display: flex;
	gap: 14px;
}

.reversal_detail_card.is-mobile .reversal_request_user-info {
	flex-wrap: wrap;
}

.reversal_detail_card.is-mobile .reversal_request_user,
.reversal_detail_card.is-mobile .reversal_request_date,
.reversal_detail_card.is-mobile .reversal_declined_tag,
.reversal_detail_card.is-mobile .reversal_accepted_tag {
	text-align: center;
}

.reversal_request_user {
	padding: 5px 12px;
	gap: 10px;
	background: #f3f9ff;
	border-radius: 95px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: right;
	letter-spacing: 0.02em;
	color: #858585;
}

.reversal_request_date {
	padding: 5px 12px;
	gap: 10px;
	background: #ededed;
	border-radius: 76px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: right;
	letter-spacing: 0.02em;
	color: #787575;
	display: inline-block;
}

.reversal_accepted_tag {
	padding: 5px 12px;
	gap: 10px;
	background: #e3ffda;
	border-radius: 76px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: right;
	letter-spacing: 0.02em;
	color: #35cb00;
}

.reversal_declined_tag {
	padding: 5px 12px;
	gap: 10px;
	background: #ffeaea;
	border-radius: 76px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: right;
	letter-spacing: 0.02em;
	color: #c80000;
}

.reversal_reason_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 23px;
	letter-spacing: 0.015em;
	color: #363636;
	margin-top: 1.2rem;
	margin-bottom: 0;
	/* margin-left: 0.5rem; */
}

.reversal_accept_button {
	background-color: #012c63;
	font-weight: 500;
	font-style: normal;
	width: 120px;
	font-family: "Montserrat";
	outline: none !important;
	color: #ffffff !important;
}

/* .reversal_accept_button:hover {
	color: #012C63 !important;
	background-color: #FFFFFF !important;
	border: 1px solid #012C63;
} */

.reversal_decline_button {
	background-color: #ffffff;
	font-weight: 500;
	font-style: normal;
	color: #af0000;
	width: 120px;
	font-family: "Montserrat";
	outline: none !important;
}

.payment_reversal_table {
	width: 100%;
	flex-grow: 1;
}

.payment_reversal_collapse_container {
	flex: 0.2;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.payment_reversal_table_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 19px;
	letter-spacing: 0.02em;
	color: #4f4f4f;
}

.payment_reversal_table_value {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 21px;
	letter-spacing: 0.015em;
	color: #363636;
}

.payment_reversal_table_value > td {
	padding-top: 0.25rem !important;
}

.payment_reversal_collapse_button {
	width: 38px;
	height: 38px;
	border: 1px solid #e5e5e5 !important;
	background-color: transparent;
	border-radius: 6px;
	align-self: center;
}

.full_batch_list_collapse_button {
	width: 38px;
	height: 38px;
	border: 0px solid #e5e5e5 !important;
	background-color: transparent;
	border-radius: 6px;
	align-self: center;
}

.reversal_detail_card .css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root {
	min-height: 0 !important;
	padding: 0 !important;
}

.reversal_detail_card .css-1betqn-MuiAccordionSummary-content {
	margin: 0 !important;
}

.reversal_detail_card .MuiAccordionDetails-root {
	padding: 0 !important;
	border-top: none !important;
}

.reversal_detail .payment_reversal_table_header > td:first-child {
	width: 25% !important;
}

.reversal_detail .payment_reversal_table_header > td:nth-child(2) {
	width: 22% !important;
}

.reversal_detail .payment_reversal_table_header > td:nth-child(3) {
	width: 26% !important;
}

.reversal_detail .payment_reversal_table_header > td:nth-child(4) {
	width: 30% !important;
}

.reversal_detail_2 .payment_reversal_table_header > td:first-child {
	width: 22% !important;
}

.reversal_detail_2 .payment_reversal_table_header > td:nth-child(2) {
	width: 19.5% !important;
}

.reversal_detail_2 .payment_reversal_table_header > td:nth-child(3) {
	width: 26% !important;
}

.reversal_detail_2 .payment_reversal_table_header > td:nth-child(4) {
	width: 30% !important;
}

.reversal_detail,
.reversal_detail_2 {
	width: 90%;
}

.reversal_accept_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 25px;
	letter-spacing: 0.02em;
	color: #000000;
	margin-bottom: 0;
	word-break: break-word;
}

.reversal_decline_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 23px;
	letter-spacing: 0.02em;
	color: #000000;
	margin-top: 2.5rem;
	margin-bottom: 0.5rem;
}

.reject_reason_textarea {
	padding: 15px 15px 80px;
	gap: 10px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 6px;
	resize: none;
	outline: none;
	width: 100%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.02em;
}

.reject_reason_error {
	margin-bottom: 0;
	color: red;
	font-family: "Montserrat";
	font-style: normal;
}

.pending_reversal .Toastify__toast {
	padding: 0 !important;
	margin-bottom: 0 !important;
}

.pending_reversal .Toastify__toast-body {
	padding: 0 !important;
	margin-bottom: 0 !important;
}

.pending_reversal .Toastify__toast-container {
	width: auto !important;
}

.pending_reversal .Toastify__toast-container--top-center {
	top: 7em !important;
}

.reversal_toast {
	padding: 1rem 2rem;
	background-color: var(--toast-status);
	color: #ffffff;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.02em;
}

.installmentList td:last-child {
	padding: 0 !important;
}

/* .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
	z-index: 999999991 !important;
} */

.payment_history_options {
	z-index: 999999991 !important;
}

.payment_history_options_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	letter-spacing: 0.02em;
	color: #afafaf;
	margin-left: 1rem;
}

.reversal_reason_container {
	padding: 20px 15px !important;

	border: 1px solid #bcbcbc;
	border-top: none;
	border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px;
	margin-bottom: -1.8rem;

	border: 1px solid #bcbcbc;
	border-top: none;
	border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px;
	margin-bottom: -1.8rem;
}
.reversal_reason_container_Lead {
	padding: 20px 15px !important;
	margin-bottom: 1rem;
}
.reversal_reason_container_Lead.small {
	margin-bottom: 0rem !important;
}

.reversal_reason_container_flag {
	padding: 10px 15px !important;

	border: 1px solid #bcbcbc;
	border-top: none;
	border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px;
	margin-bottom: -1.8rem;

	border: 1px solid #bcbcbc;
	border-top: none;
	border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px;
	margin-bottom: -1.8rem;
}
.reversal_reason_container_flag_Lead {
	padding: 20px 15px 0px 15px !important;
	margin-bottom: 0rem !important;
}

.reversal_reversal_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 23px;
	letter-spacing: 0.02em;
	color: #000000;
	margin-bottom: 0.5rem;
	margin-top: 10px;
}

.reversal_reversal_header_1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 23px;
	letter-spacing: 0.02em;
	color: #000000;
	margin-bottom: 0.5rem;
}

.reversal_reason_textarea {
	padding: 10px 10px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 6px;
	resize: none;
	outline: none;
	width: 100%;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 15px;
	letter-spacing: 0.02em;
	height: 40px;
}

.reversal_initiate_button {
	background-color: #012c63;
	font-weight: 500;
	font-style: normal;
	font-family: "Montserrat";
	outline: none !important;
	color: #ffffff !important;
}

.reversal_td_container {
	/* border-top: 0 !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important; */
	position: relative;
	top: -1.8rem;
	border: none !important;
}

.reversal_container > td {
	border-bottom: 0 !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.student_detail_delete_profile {
	z-index: 999999991 !important;
}

.reversal_raised_flag {
	margin-top: -0.5rem;
	margin-left: 0.8rem;
}

.reversal_raised_flag_wallet {
	margin-top: -0.5rem;
	margin-left: 0.8rem;
	margin-right: -0.5rem;
}

.reversal_raised_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0.015em;
	color: #012c63;
}

.reversal_raised_text.reversal_declined {
	display: inline-block;
	cursor: pointer;
	text-align: left;
	overflow: hidden;
}

.reversal_declined_content {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.6s;
	transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

.reversal_declined_content.open {
	max-height: 250px;
	overflow: hidden;
}

.reversal_raised_text.reversal_declined {
	cursor: auto;
}

.reversal_raised_text.reversal_declined .reversal_declined_tag {
	display: flex;
	align-self: flex-start;
	background: #ededed;
	color: #787575;
}

.reversal_raised_text.reversal_declined .reversal_declined_toggle {
	cursor: pointer;
	align-self: flex-start;
	background: #fff;
}

.reversal_raised_text.reversal_declined .reversal_declined_remark {
	font-family: "Montserrat";
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: 0.015em;
	color: #363636;
	margin-top: 8px;
	margin-bottom: 8px;
	word-break: break-word;
}

.reversal_raised_text.reversal_declined .reversal_declined_centre_head {
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0.015em;
	color: #9b0000;
	margin-bottom: 8px;
}

.nav_logo_container {
	min-height: 86px !important;
	padding-left: 20px;
	display: flex;
	align-items: center;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
	border-right: none !important;
}

.css-12rym3q-MuiPaper-root {
	margin-left: 0 !important;
}

.top_bar_mobile .css-i4bv87-MuiSvgIcon-root {
	width: 3rem !important;
	height: 3rem !important;
	color: #012c63 !important;
}

.top_bar_mobile .MuiBox-root {
	flex: 0;
}

/* .top_bar_mobile .css-1cndkqf-MuiToolbar-root {
    justify-content: space-between;
} */

.mobile_topbar {
	justify-content: space-between;
}

.top_bar_mobile .profile_section {
	height: 40px;
	width: 40px;
	/* background-color: #bdbdbd; */
	background-color: #ffffff;
	border-radius: 100%;
	color: #012c63;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 23px;
	font-weight: 600;
}

.top_bar_mobile .MuiPaper-root {
	background-color: #ffffff;
}

.not_found_container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 20rem;
	/* height: 100vh;
	background-color: #FFFFFF; */
}

.not_found_container_component {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 20rem;
}

.not_found_container_heading {
	font-size: 12rem;
	color: rgb(1, 44, 99);
	margin-top: -5rem;
}

.not_found_container_text {
	font-size: 2rem;
	color: rgb(1, 44, 99);
	margin-bottom: 1.5rem;
}

.not_found_page_button {
	background-color: #012c63;
	font-weight: 500;
	font-style: normal;
	font-family: "Montserrat";
	outline: none !important;
	color: #ffffff !important;
	text-transform: uppercase;
	letter-spacing: 0.05rem;
}

.spinner.spinner-white:before {
	border: 2px solid #ffffff;
	border-right: 2px solid transparent;
}

.spinner:before {
	animation: animation-spinner 0.5s linear infinite;
}

.spinner {
	position: relative;
}

.spinner:before {
	width: 2rem;
	height: 2rem;
	margin-top: -1rem;
}

.spinner:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 0;
	border-radius: 50%;
	border: 2px solid #d6d6e0;
	border-right: 2px solid transparent;
}

@-webkit-keyframes animation-spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes animation-spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader_container {
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader_scroll_container {
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swal2-container {
	z-index: 999999991 !important;
}

.topnavbar {
	z-index: 9999 !important;
}

.sidebar_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	margin-left: 1rem;

	display: none;
	position: absolute;
	top: 2rem;

	white-space: nowrap;
}

.navbar_container {
	margin-top: 12rem !important;
	overflow-y: auto !important;
	padding-bottom: 3.5rem !important;
}

.nav-list-mobile {
	overflow-y: auto;
}

.navbar_container_wrapper {
	width: 85px !important;
	transition: width 0.4s;
	position: relative;
	height: calc(100vh - 87px);
}

.navbar_container_wrapper:hover {
	width: 240px !important;
}

.navbar_container_wrapper_open {
	width: 240px !important;
	position: relative;
	height: calc(100vh - 87px);
}

.sidebar_text_open {
	display: inline-block !important;
}

.side_bar_open_arrow {
	transform: rotate(180deg);
}

.navbar_container_wrapper .MuiDrawer-paper {
	width: 85px !important;
	transition: width 0.4s;
}

.navbar_container_wrapper:hover .MuiDrawer-paper {
	width: 240px !important;
}

.navbar_container_wrapper:hover .sidebar_text {
	display: inline-block;
}

.navbar_container_wrapper.nav-collapse {
	transition-delay: 0.4s;
	width: 85px !important;
}

.navbar_container_wrapper.nav-collapse .sidebar_text {
	display: none;
}

.navbar_container_wrapper.nav-collapse .MuiDrawer-paper {
	transition-delay: 0.4s;
	width: 85px !important;
}

.nav_link_container {
	padding: 15px 0 15px 25px;
	position: relative;
}

.homePage {
	padding: 20px;
	text-align: center;
	/* height: 100vh; */
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.homePage h1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600 !important;
	font-size: 34px !important;
	line-height: 51px;
	text-align: center;
	letter-spacing: 0.02em;
	color: #000000;
	margin-top: 15rem;
}

.homePage p {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 21px;
	line-height: 42px;
	text-align: center;
	letter-spacing: 0.02em;
	color: #000000;
	max-width: 600px;
}

.student_search_input {
	border: none;
	height: 50px;
	border-radius: 8px;
	flex-grow: 1;
	padding: 0 0 0 52px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #000000;
	background-color: #ffffff;
}

.student_search_input.add_hub {
	border: 1px solid #858585;
	color: #000000;
	padding: 13px 14px;
	gap: 10px;
	height: 40px;
	outline: none;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
}

.student_search_input.add_hub.error {
	border: 1px solid #bc0000;
}

input.student_search_input:focus-visible {
	outline: none;
}

.student_search_container.is-mobile {
	margin-bottom: 1.25rem !important;
}

.student_search_container.is-mobile .student_search_input {
	font-size: 1.25rem;
	padding-left: 30px;
}

.student_search_image {
	position: absolute;
	left: 1.4rem;
}

.student_search_container.is-mobile .student_search_image {
	left: 1rem;
	width: 1.5rem;
}

.student_search_button {
	background-color: #012c63;
	font-weight: 500;
	font-style: normal;
	font-family: "Montserrat";
	outline: none !important;
	color: #ffffff !important;
	height: 50px;
	border-radius: 8px;
	margin-left: 1.5rem;
	cursor: pointer;
	min-width: 108px;
	width: 108px;
}

.student_search_container.is-mobile .student_search_button {
	padding: 4px 12px;
	max-width: 100px;
}

.student_search_button_disabled {
	background: #012c63 !important;
	opacity: 0.2 !important;
	color: #ffffff !important;
	cursor: auto !important;
}

.student_search_more_filter {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #0057bc;
	cursor: pointer;
}

.student_search_more_filter_container {
	margin-top: 1rem;
	width: 100%;
}

.student_search_container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 0 auto;
	margin-top: 20rem;
	transition: margin 1000ms ease-in-out;
	max-width: 550px;
}
.student_search_container.big {
	max-width: 750px;
	margin-top: 30rem;
}

.student_search_container-input {
	width: 100%;
	position: relative;
}

.student_search_container_filter {
	margin-top: 0;
}

.student_search_container_filter.big {
	margin-top: 0;
}

.student_search_list_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 23px;
	line-height: 21px;
	color: #000000;
	margin-left: 1.5rem;
}

.student_search_list_container.is-mobile .student_search_list_header {
	font-size: 1.75rem;
}

.student_list_back {
	cursor: pointer;
	margin-top: 0.2rem;
}

.student_search_list_container {
	/* width: 70%; */
	width: 95%;
	margin: auto;
	display: none;
}

.student_search_list_container.is-mobile {
	width: 100%;
	padding: 0 0.75rem;
}

.student_search_list_container_filter {
	display: block;
	animation: fadein linear 1s 1 normal forwards !important;
}

@keyframes fadein {
	0% {
		opacity: 0;
		height: 0;
	}

	60% {
		opacity: 0.5;
		height: auto;
	}

	100% {
		opacity: 1;
		height: auto;
	}
}

.student_data_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	isolation: isolate;
	min-height: 160px;
	background: #ffffff;
	border-radius: 8px;
	margin-bottom: var(--student_card_margin_bottom);
	margin-top: var(--student_card_margin_top);
	cursor: pointer;
}

.student_data_container_hover:hover {
	border: 1px solid #0057bc;
	box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.1);
}

.student_data_container_installment_hover:hover {
	border: 1px solid #e00000;
	box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.1);
}

.search_student_details {
	display: flex;
	width: 100%;
	align-items: center;
}

/* .student_data_container.is-mobile {
} */

.student_data_container.is-mobile .search_student_details {
	flex-wrap: wrap;
}

.search_student_details_flex {
	flex: 0.2;
	display: flex;
}

.search_student_details_content_flex {
	flex: 0.35;
	display: flex;
	align-items: center;
}

.search_student_details_flex_name {
	flex: 0.2;
	display: flex;
	justify-content: flex-start;
}

.student_data_container.is-mobile .search_student_details_flex {
	order: 1;
	flex: calc(1 / 3);
	min-width: calc(100% / 3);
}

.student_data_container.is-mobile .search_student_details_flex.profile-pic {
	order: 0;
	flex: calc(1 / 3);
	min-width: calc(100% / 3);
	margin-bottom: 1rem;
}

.student_data_container.is-mobile .search_student_details_flex_name {
	order: 0;
	flex: calc(1 / 3);
	min-width: calc(100% / 3);
}

.student_data_container.is-mobile .search_student_details_flex.view-profile {
	order: 0;
	flex: calc(1 / 3);
	min-width: calc(100% / 3);
}

.student_search_filter_applied_list {
	margin-bottom: 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.student_search_filter_applied_list.is-mobile {
	margin-bottom: 1rem;
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 10px;
	row-gap: 10px;
}

.student_search_scroll {
	max-height: calc(100vh - 29rem);
	overflow: auto;
	padding: 0 1rem;
}

.student_search_scroll_advanced {
	max-height: calc(100vh - 36rem);
	overflow: auto;
	padding: 0 1rem;
}

.search_details_flex {
	flex: 0.6;
	display: flex;
	/* justify-content: center; */
}

.search_details_flex img {
	margin-left: 0.8em;
}

.search_student_detail_profile {
	width: 80px;
	height: 80px;
	background: #daeaff;
	border-radius: 50%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 37px;
	color: #012c63;

	display: flex;
	justify-content: center;
	align-items: center;
}

.student_data_container.is-mobile .search_student_detail_profile {
	width: 70px;
	height: 70px;
	font-size: 24px;
	line-height: 1;
}

.close_button_modal {
	color: #000000;
	font-weight: 700;
	font-size: 20px;
}

.search_faculty_detail_profile {
	width: 70px;
	height: 70px;
	background: #daeaff;
	border-radius: 50%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	line-height: 37px;
	color: #012c63;

	display: flex;
	justify-content: center;
	align-items: center;
}

.list_of_subjects_faculty_modal {
	box-shadow: 8px 8px 50px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	padding: 20px;
	gap: 16px;
}

.student_data_container.is-mobile .search_faculty_detail_profile {
	width: 60px;
	height: 60px;
	font-size: 20px;
	line-height: 1;
}

.search_student_view_profile {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #0057bc;
	cursor: pointer;
}

.search_student_detail_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #8d8d8d;
	margin-bottom: 0.2rem;
}

.search_student_detail_value {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #434343;
	margin-bottom: 0;
}

.search_student_detail_id {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #434343;
}

.search_student_detail_name {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #434343;
	margin-bottom: 0.2rem;
}

.student_installment_missed {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-bottom: -2rem;
	margin-top: 0.5rem;
}

.student_data_container.is-mobile .student_installment_missed {
	margin-top: 1rem;
	margin-bottom: 0;
}

.student_installment_missed_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #e00000;
	margin-left: 1rem;
	margin-top: 1px;
}

.add_student_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	isolation: isolate;
	height: 160px;
	background: rgba(255, 255, 255, 0.2);
	border: 2px dashed #858585;
	border-radius: 8px;
	margin-bottom: 2rem;
	margin-top: 2rem;
	cursor: pointer;
}

.add_student_container_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	color: #012c63;
}

.add_student_text_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0.3;
}

.add_student_img {
	width: 3rem;
	margin-bottom: 1rem;
}

.add_student_container:hover .add_student_text_container {
	opacity: 1;
}

.add_student_container:hover {
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border: none;
}

.student_enrolled {
	width: 75px;
	height: 20px;
	position: relative;
	background: #167107;
	color: #ffffff;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.02em;
	padding: 2px 0.5rem;
}

.student_enrolled:after {
	content: "";
	position: absolute;
	bottom: 0;
	width: 0;
	height: 0;
	border-right: 10px solid #ffffff;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	right: -1px;
}

.student_free_user {
	width: 80px;
	height: 20px;
	position: relative;
	background: #f6eadc;
	color: #70573b;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.02em;
	padding: 2px 0.5rem;
}

.student_free_user:after {
	content: "";
	position: absolute;
	bottom: 0;
	width: 0;
	height: 0;
	border-right: 10px solid #ffffff;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	right: -1px;
}

.student_locked_tag {
	width: 120px;
	height: 20px;
	position: relative;
	color: #bc0000;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 0.02em;
	padding: 2px 0.5rem;
}

.student_enrolled_wrapper {
	display: flex;
	justify-content: flex-start;
	left: -1.5rem;
	margin-bottom: -0.8rem;
	position: relative;
	top: -2.2rem;
	width: 100%;
}

.student_enrolled_wrapper.is-mobile {
	top: -1rem;
}

.student_tablet_wrapper {
	position: relative;
	width: 100%;
}

.student_tablet_img {
	position: absolute;
	right: 0;
	top: -2rem;
}

.student_tablet_img.is-mobile {
	top: -1rem;
}

.student_tablet_img_no_enroll {
	position: absolute;
	right: 0;
	top: -3rem;
}

.student_tablet_img_no_enroll.is-mobile {
	top: -1rem;
}

.student_search_filter_wrapper {
	/* margin-top: 90px; */
	padding: 20px;
}

.student_search_filter_heading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: 0.02em;
	color: #0b0b0c;
}

.student_search_filter_dropdown_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #101010;
}

.filter_applied_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #858585;
	margin-bottom: 0;
}

.filter_applied_value_container {
	padding: 4px 8px;
	gap: 8px;
	background: #daeaff;
	border-radius: 80px;
	display: flex;
	flex-wrap: wrap;
}

.filter_applied_clearall_container {
	padding: 4px 8px;
	gap: 8px;
	background: #ffffff;
	border-radius: 80px;
}

.filter_applied_clearall_container_wrapper {
	margin-top: 17px;
}

.filter_applied_value {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.02em;
	color: #012c63;
	margin-bottom: 0;
}

.enrolled_courses_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	line-height: 39px;
	letter-spacing: 0.02em;
	color: #454545;
	margin-bottom: 0;
}

.student_detail_course_container {
	display: flex;
	flex-direction: column;
	padding: 44px 50px;
	width: 100%;
	background: #efefef;
	border-radius: 15px;
	margin: 20px 0px;
	background: #ffffff;
	box-shadow: 4px 4px 30px rgb(0 0 0 / 7%);
	border-radius: 15px;
}

.student_detail_name_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 39px;
	color: #000000;
	margin-bottom: 0rem;
}

.student_detail_email_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 21px;
	color: #434343;
	margin-bottom: 0;
}

.student_detail_id_container {
	padding: 2px 10px;
	width: fit-content;
	background: #ecf4ff;
	border-radius: 60px;
}

.student_detail_id_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #434343;
	margin-bottom: 0;
}

.student_detail_option_reset_pw {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #0d0c0c;
}

.student_detail_option_delete {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #f30707;
	height: 17px;
}

.student_course_container {
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
	background: #efefef;
	border-radius: 15px;
	margin: 15px 0px;
	background: #ffffff;
	box-shadow: 4px 4px 30px rgb(0 0 0 / 7%);
	border-radius: 15px;
}

.student_course_container.is-mobile {
	padding: 15px;
}

.student_course_container.course_inactive .course-status-text {
	color: #c50404;
}

.course_inactive {
	background: #e8e7e7 !important;
}

.student_course_detail_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #000000;
}

.student_course_detail_value {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	color: #000000;
	margin-bottom: 0;
	margin-top: 0.5rem;
}

.student_course_detail-payment {
	display: flex;
}

.student_course_detail-payment.responsive {
	flex-direction: column;
	gap: 10px;
	column-gap: 10px;
}

label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.student_course_toggle_text {
	display: flex !important;
	flex-direction: row-reverse !important;
	margin-right: 0;
	margin-left: 0;
}

.student_course_toggle_text .MuiFormControlLabel-label .student_course_toggle-label {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #000000;
}

@media screen and (max-width: 420px) {
	.student_course_toggle_text .MuiFormControlLabel-label .student_course_toggle-label {
		font-size: 13px;
	}
}

.student_course_active_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #038200;
	margin-right: 10px;
	float: right;
}

.student_course_inactive_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #c50404;
	margin-right: 10px;
	float: right;
}

.student_course_detail_centre_flex {
	flex: 0.3;
}

.student_course_detail_centre_flex_name {
	flex: 0.3;
}

.search_student_details.is-mobile {
	flex-wrap: wrap;
}

.search_student_details.is-mobile .search_details_flex {
	flex: 0.9;
	min-width: 85%;
	margin-bottom: 0.5rem;
	order: 0;
}

.search_student_details.is-mobile .search_details_flex img {
	margin-left: 0;
}

.search_student_details.is-mobile .search_student_details_flex {
	flex: 0.33;
	justify-content: flex-start;
}

.search_student_details.is-mobile .search_student_details_flex .search_student_detail_header {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.search_student_details.is-mobile .search_student_details_flex.options {
	flex: 0.1;
	order: 1;
}

.search_student_details.is-mobile .search_student_details_content_flex.last-paid {
	flex: 1;
	order: 2;
	margin-top: 1rem;
}

.search_student_details.is-mobile .search_student_details_flex.phone-number {
	margin-left: 0px;
}

.search_student_details.is-mobile .search_student_details_flex:not(.options) {
	order: 2;
	flex: 0.5;
}

.search_student_details.is-mobile .search_student_detail_profile {
	width: 70px;
	height: 70px;
	font-size: 24px;
	line-height: 1;
}

@media screen and (max-width: 440px) {
	.search_student_details.is-mobile .search_student_detail_profile {
		width: 60px;
		min-width: 60px;
		height: 60px;
	}
}

.student_course_container.is-mobile .enrolled-course-details {
	flex-wrap: wrap;
}

.student_course_container.is-mobile .student_course_detail_centre_flex,
.student_course_container.is-mobile .student_course_detail_others_flex {
	flex: 1;
	min-width: 100%;
	margin-bottom: 1.25rem;
}

.student_course_container.is-mobile .enrolled-course-toggle {
	width: 100%;
}

.student_course_container.is-mobile
	.enrolled-course-toggle
	label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.student_course_toggle_text {
	flex-direction: row !important;
}

.student_course_container.is-mobile .enrolled-course-toggle .student_course_toggle_text span:nth-child(1) {
	order: 2;
}

.student_course_container.is-mobile .enrolled-course-toggle .student_course_toggle_text span:nth-child(2) {
	order: 1;
}

.student_course_container.is-mobile .student_course_active_text {
	float: unset;
}

.student_course_detail_others_flex {
	flex: 0.4;
}

.upload_signed_form_button {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 26px;
	color: #000000;
	background-color: #eff6ff;
	border-radius: 8px;
	height: 40px;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
	outline: none !important;
}

.student-detail-fee-summary {
	text-align: right;
}

.student_course_container.is-mobile .student-detail-fee-summary {
	text-align: unset;
	margin-top: 1rem;
}

.student_detail_fee_summary_button {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 26px;
	letter-spacing: 0.02em;
	color: #ffffff !important;
	background-color: #012c63;
	border-radius: 8px;
	height: 40px;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
	outline: none !important;
}

.student_detail_fee_summary_button:hover {
	color: #ffffff !important;
}

.student_signed_form .modal-content {
	padding: 40px !important;
}

.student_signed_form.is-mobile .modal-content {
	padding: 20px !important;
}

.student_signed_form button.close {
	font-size: 40px !important;
	right: 45px !important;
	top: 36px !important;
}

.student_signed_form.is-mobile button.close {
	right: 20px !important;
	top: 14px !important;
}

.signed_form_container {
	padding: 20px;
	gap: 20px;
	background: #f8f8f8;
	border-radius: 8px;
	margin-bottom: 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.student_signed_form.is-mobile .signed_form_container {
	padding: 14px;
	gap: 14px;
	overflow: hidden;
	font-size: 0.8rem;
	display: flex;
	flex-wrap: wrap;
}

.admission_form_text_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 25px;
	color: #000000;
	margin-bottom: 0.5rem;
}

.student_signed_form.is-mobile .admission_form_text_header {
	font-size: 14px;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.student_signed_form.is-mobile .signed_form_container-info {
	flex: 1 0 auto;
	width: 100%;
}

.admission_form_text_sub_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #000000;
	margin-bottom: 0;
}

.admission_form_view {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 33px;
	color: #012c63;
	cursor: pointer;
}

.upload_form_header {
	padding: 1.5rem;
	gap: 10px;
	height: 60px;
	background: #ffffff;
	border-radius: 8px;
	display: flex;
	align-items: center;
}

.upload_form_header_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #000000;
}

.upload_form_container {
	padding: 20px;
	gap: 45px;
	background: #ffffff;
	box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
	border-radius: 24px;
	margin-top: 2rem;
	margin-bottom: 0rem;
}

.upload_signed_form_detail_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #0c0d0c;
}

.upload_signed_form_detail_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 1.8rem;
	margin-bottom: -2.2rem;
}

.course_container {
	height: calc(100vh - 35rem);
	overflow: auto;
	padding: 0 0.8rem;
}

.course_container.is-mobile {
	height: auto;
	padding: 0;
}

.student_detail_delete_profile .css-15ypy0o {
	border-radius: 8px !important;
	margin-top: -5rem !important;
}

.student_detail_delete_sub_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.02em;
	color: #000000;
	margin-top: 1rem;
}

.student_detail_no {
	padding: 6px 20px;
	gap: 10px;
	background: #eff6ff;
	border-radius: 8px;
	outline: none !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #181717;
}

.student_detail_delete_confirm {
	padding: 6px 20px;
	gap: 10px;
	background: #c00000;
	border-radius: 8px;
	outline: none !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff !important;
}

.student_detail_delete_confirm:hover {
	color: #ffffff !important;
}

.student_detail_password_confirm {
	padding: 6px 20px;
	gap: 10px;
	background: #012c63;
	border-radius: 8px;
	outline: none !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff !important;
}

.student_detail_password_confirm:hover {
	color: #ffffff !important;
}

.add_course_modal_index {
	z-index: 13000 !important;
}

legend {
	width: auto !important;
}

.add_course_dropdown_text {
	font-family: "Montserrat" !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	/* line-height: 18px !important;	 */
	/* color: #858585 !important	; */
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
	font-size: 1.2rem !important;
}

.add_course_drop_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #101010;
	margin-bottom: 4px;
}

.student_filter_drop_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #101010;
	margin-bottom: 7px;
}

.student_detail_add_course_button {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.02em;
	color: #ffffff !important;
	background-color: #012c63;
	border-radius: 8px;
	height: 40px;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
	outline: none !important;
}

.student_detail_add_course_button:hover {
	color: #ffffff !important;
}

.student_search_filter_button {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.02em;
	color: #ffffff !important;
	background-color: #012c63;
	border-radius: 8px;
	height: 35px;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
	outline: none !important;
	min-width: 100px;
}

.student_search_filter_button:hover {
	color: #ffffff !important;
}

.student_search_filter_drawer {
	z-index: 13000 !important;
}

.student_search_filter_drawer_clear {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #0057bc;
	cursor: pointer;
}

.student_add_progress_line {
	height: 4px;
	background-color: #e97a26;
}

.student_add_container {
	padding-top: 4rem;
}

.profile_wrapper {
	background-color: transparent !important;
	outline: none !important;
}

.mui_dropdown_options:hover {
	background-color: #e4f0ff !important;
}

.student_detail_option_reset_pw:hover {
	font-weight: 500;
}

.student_detail_option_delete:hover {
	font-weight: 500;
}

.change_pw_input {
	padding: 13px 14px;
	gap: 10px;
	height: 40px;
	border: 1px solid #858585;
	border-radius: 6px;
	outline: none;
	width: 100%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
}

.change_pw_input_error {
	border: 1px solid #bc0000;
}

.error_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #bc0000;
	margin-left: 0.2rem;
	position: relative;
	top: 0.1rem;
}
.error_text.big {
	font-size: 16px;
}

.error_img {
	width: 1.4rem;
	vertical-align: middle;
}

.add_student_progress_line1 {
	flex: 0.5;
	height: 4px;
	background-color: #e97a26;
}

.add_student_progress_line2 {
	flex: 0.5;
	height: 2px;
	background-color: #012c63;
}

.add_student_progress_container {
	padding: 10px;
	gap: 10px;
	width: 48px;
	height: 48px;
	background: #012c63;
	border-radius: 100%;
	margin-top: var(--student_progress);
	display: flex;
	justify-content: center;
	align-items: center;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #ffffff;
}

.add_student_progress_container_selected {
	border: 2px solid #e97a26;
	width: 65px;
	height: 65px;
	border-radius: 100%;
	margin-top: -3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
}

.step1_progress2 {
	margin-left: -30rem;
}

.step1_progress3 {
	margin-left: 10rem;
}

.step2_progress3 {
	margin-right: 10rem;
}

.student_details_container {
	padding: 30px 40px;
	gap: 20px;
	background: #ffffff;
	border-radius: 8px;
	min-height: 455px;
}

.student_details_edit_container {
	padding: 30px;
	gap: 20px;
	background: #ffffff;
	border-radius: 8px;
}

.student_details_wrapper {
	display: inline-block;
	width: 100%;
	padding: 40px;
}

.student_details_form {
	max-width: 350px;
	width: 100%;
	margin: 0 auto;
}

.student_details_edit_form {
	width: 350px;
}

.student_add_progress_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: 0.02em;
	color: #000000;
	position: relative;
	left: -2.5rem;
	top: var(--progress_text);
}

.add_student_back {
	padding: 10px 20px;
	gap: 10px;
	background: #eff6ff;
	border-radius: 8px;
	outline: none !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #012c63;

	border: 1px solid rgba(1, 44, 99, 0.2);
}

.add_student_next {
	padding: 10px 25px;
	gap: 10px;
	background: #012c63;
	border-radius: 8px;
	outline: none !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 27px;
	color: #ffffff !important;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.add_student_next:hover {
	color: #ffffff !important;
}

.add_student_student_icon {
	position: relative;
	top: 2.8rem;
	left: 1rem;
}

.add_student_student_icon_input {
	padding-left: 3rem;
}

.student_add_mobile_input {
	flex: 0.8;
	margin-left: 1.5rem;
}

.student_add_mobile_indian {
	flex: 0.25;
	padding-left: 3rem;
	padding-right: 0rem;
}

.student_mobile_drop_img {
	position: relative;
	left: 4.5rem;
	top: 2.8rem;
}

@media screen and (max-width: 400px) {
	.student_mobile_drop_img {
		left: 4rem;
	}
}

.student_mobile_indian_img {
	position: relative;
	top: 2.8rem;
	left: 0.9rem;
}

.student_text_bottom {
	margin-bottom: -1.2rem !important;
}

.payment_installment_container {
	padding: 40px 80px;
	gap: 30px;
	background: #ffffff;
	border-radius: 8px;
}

@media screen and (max-width: 900px) {
	.payment_installment_container {
		padding: 20px calc(0.8em + 15px);
	}

	.payment_installment_container-col {
		gap: 1rem;
		display: flex;
		flex-direction: column;
	}

	.assign_reviewer_container_pending {
		width: 48%;
		height: 250px !important;
	}
}

.create_installment_button {
	padding: 5px 45px;
	gap: 10px;
	background: #012c63;
	border-radius: 8px;
	outline: none !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 27px;
	color: #ffffff !important;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.create_installment_button:hover {
	color: #ffffff !important;
}

.total_fee_installment_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	color: #101010;
}

.total_fee_installment_amount {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 29px;
	letter-spacing: 0.02em;
	color: #0c0d0c;
}

.total_fee_installment_discount_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #101010;
	margin-bottom: 0.5rem;
}

.installment_counter_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 18px !important;
	color: #101010 !important;
}

.installment_course_detail_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #8d8d8d;
}

.installment_detail_meta_container {
	padding: 10px 20px;
	gap: 20px;
	background: #ffffff;
	border: 1px solid #000000;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
}

.installment_detail_meta_container.will-wrap {
	flex-wrap: wrap;
}

.installment_detail_meta_container.edit-installments.responsive {
	flex-direction: column;
	gap: 1rem;
	padding: 10px;
}

.installment_amount_alert {
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
	align-items: center;
}

.installment_amount_alert_text {
	color: #ff0000;
	font-weight: 600;
	font-size: 1.1em;
	padding: 8px 0px 10px 6px;
	margin: 0px;
}

.installment_meta_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #8d8d8d;
	margin-bottom: 0.5rem;
}

.installment_meta_sub_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #434343;
	margin-bottom: 0;
}

.installment_meta_sub_header_amount {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: var(--installment_total_amount);
	margin-bottom: 0;
}

.installment_details_table_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #4f4f4f;
}

.installment_details_table_data_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #000000;
}

.installment_details_table_data_text_amount {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #505050;
	position: relative;
}

.add_installment_container {
	height: 120px !important;
}

.fee_summary_tab {
	background: #f2f2f2;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #000000 !important;
	border-radius: 8px 8px 0px 0px !important;
	padding: 10px 21px !important;
}

.dots {
	background: #f2f2f2;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: bolder;
	font-size: 17px;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #000000 !important;
	border-radius: 8px 8px 0px 0px !important;
	padding: 8px 21px 12px 21px !important;
}

.fee_summary_tab_selected {
	font-weight: 500 !important;
	font-size: 18px !important;
}

.nav-tabs li.active a.fee_summary_tab.fee_summary_tab_selected {
	border-color: white;
}

.fee_summary_table_data_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #000000;
}

.student_fee_summary_detail_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #000000;
}

.student_fee_summary_detail_header_light {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #8d8d8d;
}

.student_fee_summary_detail_value {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #000000;
	margin-bottom: 0;
	margin-top: 0.5rem;
}

.reversal_confirmation_container {
	z-index: 999999992 !important;
}

.payment_history_selector {
	padding: 6px 10px;
	gap: 8px;
	width: 72px;
	height: 32px;
	background: #f9f9f9;
	border-radius: 56px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.02em;
	color: #000000;

	cursor: pointer;
}

.payment_history_selector_active {
	padding: 6px 10px;
	gap: 8px;
	width: 100px;
	height: 32px;
	background: #ffe8d7;
	border-radius: 56px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.02em;
	color: #e97a26;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.payment_history_close {
	font-size: 40px !important;
	color: #000;
	opacity: 1;
	font-weight: 400;
	position: absolute;
	right: 60px !important;
	top: 45px !important;
}

.payment_history_table_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #4f4f4f;
}

.payment_history_table_value {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #000000 !important;
}

.payment_history_table_date_value {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 27px;
	display: flex;
	align-items: center;
	letter-spacing: 0.06em;
	color: #505050 !important;
}

.payment_pay_close_button {
	font-size: 40px !important;
	right: 50px !important;
	top: 46px !important;
}

.sidebar_nav_option {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 21px;
	gap: 10px;
	position: absolute;
	width: 100%;
	height: 32px;
	background: #f2f6ff;
	bottom: 0;
	left: 0;
	cursor: pointer;

	z-index: 1000000;
}

.side_nav .MuiPaper-root {
	overflow: hidden;
}

.payment_reversal_cofirmation_modal {
	z-index: 13000 !important;
}

.MuiModal-root {
	z-index: 999999991 !important;
}

.tooltip_header {
	z-index: 130009999999 !important;
}

.delete_profile_radio_option .MuiTypography-root {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.02em;
	color: #000000;
}

.delete_profile_radio_option {
	margin-bottom: 0;
}

.student_search_empty_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.student_search_empty_img_container {
	background-color: #ffffff;
	border-radius: 100%;
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
}

.payment_history_empty_container {
	background-color: #fcfcfc;
	border-radius: 100%;
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
}

.student_search_empty_img {
	width: 65px;
}

.student_search_empty_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 29px;
	text-align: center;
	color: #000000;
	margin-top: 1.2rem;
}

.student_search_empty_label {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 27px;
	text-align: center;
	color: #000000;
}

.student_details_edit_header_container {
	padding: 6px;
	gap: 10px;
	/* background: #e9e9e9; */

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.02em;
	color: #000000;
	margin: 1rem 0;
}

.mobile_header_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 27px;
	text-transform: capitalize;
	color: #0e0d0d;
}

.reversal_icon_img {
	width: 1.3rem;
}

.mobile_nav_profile {
	background: #f3f9ff;
	border-radius: 8px;
	padding: 20px 10px;
	gap: 10px;
	/* height: 220px; */
	margin: 2rem;
	text-align: center;
}

.mobile_profile_username {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #000000;
	position: relative;
	top: 1rem;
}

.mobile_profile_role {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	font-size: 15px;
	line-height: 20px;
	color: #000000;
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
	justify-content: center;
}

.mobile_password_change {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	text-decoration-line: underline;
	color: #000000;
	margin-bottom: 0;
	margin-top: 1rem;
}

.center-badge-mobile {
	padding: 6px 10px;
	height: 29px;
	background: #ffffff;
	border-radius: 37px;
	margin: 6px 10px;
	display: inline-block;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.02em;
	color: #666666;

	white-space: nowrap;
}

.center-badge-mobile-wrapper {
	display: flex;
	justify-content: center;
	margin-top: 0.5rem;
}

.mobile_nav_tooltip {
	z-index: 999999992 !important;
}

.sidebar_nav_option_logout {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* position: absolute; */
	width: 100%;
	bottom: 7rem;
	cursor: pointer;
	background: none;
	/* z-index: 1000000; */
}

.sidebar_nav_option_logout_wrapper {
	background-color: #fff8f8;
	padding: 10px 20px;
	gap: 17px;
	display: flex;
	border-radius: 57px;
}

.nav_mobile_logout {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #e80000;
	margin-bottom: 0;
}

.mav_mobile_name_container {
	width: 65px;
	height: 65px;
	background: linear-gradient(0deg, #dee5fa, #dee5fa), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
	border-radius: 50%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 36px;
	color: #4b6ccf;

	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}

.payment_link_error_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	isolation: isolate;
	height: 160px;
	background: #ffffff;
	border-radius: 8px;
	margin: 3rem;
	height: 350px;
}

.payment_link_error_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 39px;
	color: #000000;
	margin-bottom: 0;
	margin-top: 2rem;
}

.payment_link_error_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;
	text-align: center;
	color: #434343;
}

.box-att-con {
	display: flex;
	align-items: center;
	justify-content: center;
}

.att-btn {
	margin-top: 10% !important;
	background: #f3f9ff;
	border: 1px solid #f3f9ff;
	border-radius: 8px;
	max-width: 50%;
	padding: 9% 7%;
	margin: 1%;
	font-weight: 500;
	text-align: center;
}

.att-btn:hover {
	margin-top: 10% !important;
	background: #ffebdc;
	border: 1px solid #f97b26;
	border-radius: 8px;
	max-width: 50%;
	padding: 9% 7%;
	margin: 1%;
	font-weight: 500;
	cursor: pointer;
	text-align: center;
}

.modal {
	z-index: 999;
}

.modal-btn-submit,
.modal-btn-submit:hover,
.modal-btn-submit:focus {
	background-color: #012c63;
	color: #ffffff;
	border-radius: 4px;
	padding: 12px 31px;
}

.modal-btn-submit-disabled,
.modal-btn-submit-disabled:hover,
.modal-btn-submit-disabled:focus {
	background-color: #bdc9d9;
	color: #ffffff;
	border-radius: 4px;
	padding: 12px 31px;
	cursor: not-allowed !important;
}

.close-modal-btn {
	background-color: #ffffff;
	color: #000000;
	font-weight: 600;
	border: 0;
	font-size: 18px;
}

.mark-att-modal-head {
	font-weight: 600;
	align-self: center;
	font-size: 18px;
}

.body-col {
	background: #e5e5e5;
}

.topHeader {
	position: sticky;
	top: 0;
	z-index: 100;
}

.top-bar-con {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.inside-flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.search-input {
	min-width: 250px;
}

.search-btn,
.search-btn:hover,
.search-btn:focus {
	background-color: #1f3061;
	color: #ffffff;
	padding: 7px;
	margin: 10px;
}

.right-inside-tags {
	padding: 8px;
	background-color: #ffffff;
	margin: 10px;
	border-radius: 4px;
}

.right-inside-tags:last-child {
	margin-right: 0;
}

.divider-span {
	color: #858585;
	opacity: 0.2;
	font-size: 18px;
}

.bottom-submit-con {
	position: sticky;
	bottom: 0;
	background-color: #ffffff;
	padding: 20px;
}

@media screen and (min-width: 481px) {
	.bottom-submit-con {
		padding: 20px 40px;
	}
}

.submit-att-btn,
.submit-att-btn:hover,
.submit-att-btn:focus {
	background-color: #1f3061;
	color: #ffffff;
	padding: 10px;
	font-size: 19px;
}

.footer {
	position: fixed;
	bottom: 0;
	/* width: 108%; */
	display: flex;
	background-color: #ffffff;
	justify-content: space-between;
	box-shadow: 0px -10px 50px rgba(0, 0, 0, 0.07);
	padding-right: 50;
}

.student-name-tbl {
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 0.25rem;
}

.student-id-tbl {
	font-weight: 500;
	color: #737373;
	font-size: 17px;
	margin-bottom: 0rem;
}

.mobile-number-tbl {
	font-weight: 500;
	font-size: 20px;
	margin-left: 35px;
	margin-bottom: 0;
}

.att-check:hover {
	cursor: pointer;
}

input.att-check {
	width: 22px;
	height: 22px;
}

input.mark-all-chck {
	width: 22px;
	height: 22px;
}

.mark-all-chck:hover {
	cursor: pointer;
}

.present-div-selected {
	background: #f5fff1;
	border-radius: 28px;
	padding: 7px 15px;
	margin-left: 15px;
	color: #258e00;
}

.offline-div-selected {
	background: #f3f3f3;
	border-radius: 28px;
	padding: 7px 15px;
	margin-left: 15px;
	color: #5f5f5f;
}

.present-div-cont {
	align-items: center;
}

.present-hand-img {
	width: 28px;
	margin-right: 10px;
}

.mod-head-conf {
	font-weight: 600;
	font-size: 18px;
	margin-top: 10px;
}

.cancel-btn,
.cancel-btn:hover,
.cancel-btn:focus {
	background-color: #e0eeff;
	color: #012c63;
	border: 1px solid #bad3f2;
	padding: 10px 25px;
	font-size: 19px;
}

.submit-att-btn-2,
.submit-att-btn-2:hover,
.submit-att-btn-2:focus {
	background-color: #1f3061;
	color: #ffffff;
	padding: 10px 45px;
	font-size: 19px;
	border-radius: 10px;
}

/* td:first-child,
	th:first-child {
		border-radius: 20px 0 0 20px;
	}
	td:last-child,
	th:last-child {
		border-radius: 0 20px 20px 0;
	} */

.nameInitialAvatar {
	align-items: center;
	justify-content: center;
	/* margin-top: 10px; */
	color: rgba(147, 171, 242, 1);
	font-weight: 600;
	font-family: "Montserrat";
	font-style: normal;
	font-size: 30px;
	line-height: 37px;
	color: #012c63;
	margin-bottom: 0;
}

.course-status {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.course-status .course-status-text {
	height: 38px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight: 500;
	font-size: 15px;
	line-height: 1;
	color: #038200;
	margin-bottom: 5px;
}

input[type="checkbox"].MuiSwitch-input {
	margin: 0 !important;
}

@media screen and (max-width: 396px) {
	.course-status .course-status-text {
		font-size: 14px;
	}

	.student_course_toggle_text {
		font-size: 13px;
	}
}

@media screen and (max-width: 420px) {
	.course-status .course-status-text {
		height: 24px;
	}
}

@media screen and (max-width: 796px) {
	.modal.fade.paymentPopup .modal-dialog {
		margin-top: 50px;
	}

	.modal.fade.paymentPopup .modal-content {
		padding: 15px 8px;
	}

	.modal.fade.paymentPopup button.close.payment_pay_close_button,
	.modal.fade.paymentPopup button.close.payment_history_close {
		top: 8px !important;
		right: 16px !important;
	}

	.modal.fade.paymentPopup .installmentList {
		width: 100%;
	}

	.modal.fade.paymentPopup .payment_history_table_header {
		padding: 6px !important;
		line-height: 1;
		font-size: 14px;
	}

	.modal.fade.paymentPopup .payment_history_selector,
	.modal.fade.paymentPopup .payment_history_selector_active {
		border-radius: 6px;
		font-size: 12px;
		line-height: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
	}

	.modal.fade.paymentPopup .discountWrpField {
		width: 100% !important;
	}

	.modal.fade.paymentPopup .contain {
		margin-bottom: 8px;
	}

	.student_search_empty_header {
		font-size: 14px;
	}
}

@media screen and (max-width: 480px) {
	.Toastify__toast-container.Toastify__toast-container--top-center.reset-pwd-toast {
		top: 6rem !important;
		left: 50%;
		transform: translate(-50%, 0);
		white-space: nowrap;
	}

	.Toastify__toast-container.Toastify__toast-container--top-center.reset-pwd-toast .Toastify__toast {
		border-radius: 6px;
	}

	.containerMain {
		padding-inline: 19px !important;
	}

	.att-web-table {
		display: none;
	}

	.history-web-table {
		display: none;
	}

	.reversal_reversal_header {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		letter-spacing: 0.02em;
		color: #000000;
		margin-bottom: 0.5rem;
	}

	.payment_reversal_container {
		height: 100vh;
	}

	.payment_reversal_container2 {
		height: auto;
	}

	.reversal_reason_container {
		padding: 0px !important;
		border: none;
		margin-top: 25px;
	}

	.reversal_reason_textarea {
		padding: 7px;
		height: auto;
	}

	.reversal_initiate_button {
		width: 30%;
		white-space: pre-line;
	}

	.initiate_reversal_dropdown {
		width: auto !important;
	}

	.options-container-div {
		margin-left: 0em !important;
	}

	.accordion-details-hide.MuiAccordionDetails-root {
		padding: 0;
		padding-bottom: 10px;
		border: none;
	}

	.reversal_reason_container_flag {
		padding: 0 !important;
		border: none;
		border-top: none;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		margin-bottom: 0rem;
		margin-top: 30px;
	}

	.reversal_detail {
		width: 100%;
	}
}

@media screen and (min-width: 480px) {
	.att-mob-cards {
		display: none;
	}

	.history-mob-cards {
		display: none;
	}
}

.options-container-div {
	margin-left: 3em;
}

.att-mob-cards {
	margin-bottom: 90px;
}

.top-bar-card {
	background-color: #ffffff;
	border-radius: 5px;
	padding: 8px;
	font-weight: 600;
	font-size: 19px;
	display: flex;
	justify-content: flex-start;
	padding-left: 30px;
	align-items: center;
}

.attendance-card {
	background-color: #ffffff;
	border-radius: 5px;
	padding: 8px;
	margin: 8px 0px;
}

.attendance-card-header {
	display: flex;
	margin: 5px 12px;
}

.attendance-card-stud-details-1 {
	align-items: center;
	margin-top: 5px;
}

.attendance-card-stud-details-2 {
	margin: 4px 12px;
}

.mobile-number-card {
	font-weight: 500;
	font-size: 20px;
}

.att-card-input-con {
	margin: 4px 12px;
	font-weight: 500;
	font-size: 20px;
	min-height: 45px;
}

.history-card-container {
	margin: 10px 0px;
}

.acc-inside-header {
	width: 90%;
	display: flex;
	justify-content: space-between;
	padding: 10px 0px;
}

.paid-on {
	align-self: center;
}

.paid-on-small {
	color: #8d8d8d;
	font-size: 12px;
	font-weight: 500;
}

.paid-on-big {
	font-weight: 500;
	font-size: 14px;
}

.accordion-container-main {
	padding: 0px 10px;
	border: 0.5px solid #8e8e8e;
	border-radius: 8px;
	margin: 10px 0px;
}

.accordion-container {
	display: flex;
	justify-content: space-between;
}

.accordion-inside {
	display: flex;
	/* justify-content: space-between; */
}

.paid-on-inside {
	align-self: center;
	margin-right: 10px;
}

.course_add_container {
	display: inline-block;
	width: 100%;
	padding: 30px 60px;
}

.course_add_header_container {
	padding: 6px;
	gap: 10px;
	background: #e9e9e9;
	border-radius: 8px;
	margin-top: 1.7rem;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.02em;
	color: #000000;
}

.course_add_data_container {
	background: #ffffff;
	border-radius: 8px;
	margin-top: 1rem;
	padding: 20px;
	gap: 20px;
}

.academic_details_header_container {
	padding-inline: 15px;
	padding-bottom: 15px;
	gap: 10px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	/* identical to box height, or 150% */

	letter-spacing: 0.02em;
}

.academic_details_data_container {
	background: #f6f6f6;
	border-radius: 8px;
	margin-top: 1rem;
	padding: 20px;
	gap: 20px;
}

.full_batch_list_add_data_container {
	background: #ffffff;
	border-radius: 8px;
	margin-top: 1rem;
	padding: 20px;
	gap: 20px;
	margin-bottom: 124px;
}

.course_add_back {
	padding: 5px 20px;
	gap: 10px;
	background: #eff6ff;
	border-radius: 8px;
	outline: none !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #012c63;

	border: 1px solid rgba(1, 44, 99, 0.2);
}

.course_add_next {
	padding: 5px 25px;
	gap: 10px;
	background: #012c63;
	border-radius: 8px;
	outline: none !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 27px;
	color: #ffffff !important;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.containerWhite {
	background-color: #ffffff;
	border-radius: 8px;
	border-radius: 8px;
	align-items: center;
	margin: 40px 40px 40px 40px;
	padding: 20px 70px 30px 70px;
}

.addHubContainer {
	background-color: #fbfbfb;
	padding: 15px;
	border-radius: 8px;
	gap: 24px;
	margin-top: 15px;
	padding-inline: 36px;
	align-items: center;
}

.addHubContaineredit {
	background-color: #fbfbfb;
	padding: 15px;
	border-radius: 8px;
	gap: 24px;
	margin-top: 25px;
	padding-left: 20px;
	align-items: center;
}

.add_hub_button {
	background: #f8fbff;
	border: 1px solid rgba(1, 44, 99, 0.2);
	border-radius: 10px;
	padding: 13px 35px;
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	color: #012c63;
	font-family: "Montserrat";
	font-style: normal;
	line-height: 27px;
	letter-spacing: 0.02em;
	display: flex;
	justify-content: center;
	align-items: center;
}

.add_hub_button.create {
	background: #012c63;
	color: #ffffff;
	width: 108px;
}

.filesBox.add_hub {
	margin: 5px 3px;
	max-width: 10000px;
	width: 100%;
	cursor: pointer;
}

.button_row_add_hub {
	display: flex;
	justify-content: space-between;
	padding: 0px 140px 3rem 140px;
}

@media screen and (max-width: 555px) {
	.containerWhite {
		margin: 10px 10px 10px 10px;
		padding: 5px 5px 5px 5px;
	}
}

.antd_dropdown .ant-select-selector {
	/* antd override */
	border: 1px solid #858585 !important;
	border-radius: 6px !important;
	min-height: 40px !important;
}

.antd_dropdown .ant-select-multiple .ant-select-selection-item-content {
	/* antd override */
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #000000;
}

.antd_dropdown .ant-select-multiple .ant-select-selection-item {
	/* antd override */
	border-radius: 10px !important;
	flex-direction: row-reverse;
}

.antd_dropdown .ant-select-multiple .ant-select-selection-item-remove {
	/* antd override */
	color: #000000;
	font-size: 1.2rem;
	position: relative;
	top: -0.2rem;
	margin-right: 4px;
}

.antd_dropdown .ant-select-selection-placeholder {
	/* antd override */
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 16px;
	color: #858585;
}

.antd_dropdown .ant-select-multiple .ant-select-arrow {
	/* antd override */
	top: 20px !important;
}

.antd_dropdown .ant-select-multiple .ant-select-clear {
	/* antd override */
	top: 20px !important;
}

.antd_dropdown .ant-select-selection-item {
	/* antd override */
	margin-top: 0.35rem;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	/* line-height: 16px !important; */
	color: #100f0f;
}

.antd_dropdown .ant-select-single .ant-select-selection-placeholder {
	margin-top: 0.2rem;
}

.antd_dropdown .ant-select-single .ant-select-selection-search {
	margin-top: 0.35rem;
}

.add_course_resource_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #000000;
}

.add_course_division {
	margin-top: 1.75em;
}

.add_course_resource_container {
	display: flex;
	align-items: center;
	margin-top: -0.5rem;
}

.button_edit {
	border: none;
	border-radius: 60px;
	background: #eaf3ff;
	padding: 6px 25px;
	gap: 10px;
	color: #0057bc;
}

.topHeader2 {
	position: sticky;
	top: 120px;
	z-index: 1000;
}

.footer .footer-attendance {
	width: 100%;
	height: 90px;
}

.containerMain.containerMain-attendance {
	height: calc(100% - 27px);
	padding-bottom: 0;
}

.att-mob-cards,
.att-web-table {
	height: calc(100% - 62px - 62px);
}

@media screen and (max-width: 768px) {
	.containerWhite {
		margin: 10px 10px 10px 10px;
		padding: 8px 8px 8px 8px;
	}

	.button_row_add_hub {
		margin-top: 20px;
		padding: 0px 30px;
	}

	.addHubContainer {
		padding-inline: 20px !important;
	}

	.filesBox.add_hub {
		width: 100%;
	}

	.footer .footer-attendance {
		width: 100%;
		height: 90px;
	}

	.student_search_input.add_hub {
		padding: 1px 1px 1px 15px;
	}
}

@media screen and (min-width: 481px) {
	.top-bar-con {
		margin-bottom: 5px;
		position: sticky;
		top: 87px;
		z-index: 9999;
	}
}

@media screen and (max-width: 480px) {
	.top-bar-con {
		top: 0 !important;
	}

	.footer.footer-attendance {
		position: relative;
		margin-top: 20px;
	}
}

.course_search_input {
	border: 1px solid #858585;
	height: 40px;
	border-radius: 8px;
	max-width: 350px;
	width: 100%;
	padding: 3px 3px 3px 40px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #000000;
	background-color: #ffffff;
	outline: none;
}

.course_search_input2 {
	border: 1px solid #858585;
	height: 40px;
	border-radius: 8px;
	/* max-width: 350px; */
	width: 100%;
	padding: 3px 3px 3px 20px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #000000;
	background-color: #ffffff;
	outline: none;
	margin: 10px;
	/* margin-bottom: 10px; */
}

.course_search_wrapper {
	width: 100%;
	max-width: 350px;
}

.payment_details_search_wrapper {
	width: 100%;
	max-width: 450px;
}

.course_search_image {
	position: relative;
	left: 3rem;
	top: -0.05rem;
	width: 1.7rem;
}

.course_list_header {
	padding: 1.5rem;
	background-color: #ffffff;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2rem;
}

.course_list_header2 {
	padding: 1.5rem;
	background-color: #ffffff;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2rem;
}

.add_course_inr .ant-select-selector {
	background-color: #ffffff !important;
}

.course_list_container {
	display: inline-block;
	width: 100%;
	padding: 30px;
}

.mpp_list_container {
	display: inline-block;
	width: 100%;
	padding: 20px 30px;
}

.course_list_card {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	/* flex-direction: column; */
	padding: 20px;
	gap: 20px;
	background: #ffffff;
	border-radius: 8px;
	margin-top: 1.2rem;
	flex-wrap: wrap;
}

.faculty_list_card {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	/* flex-direction: column; */
	padding: 20px;
	gap: 20px;
	background: #f9f9f9;
	border-radius: 8px;
	margin-top: 1.2rem;
	flex-wrap: wrap;
	width: 100%;
}

.group_list_card {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	/* flex-direction: column; */
	padding: 20px;
	gap: 20px;
	background: #ffffff;
	border-radius: 8px;
	margin-top: 1.2rem;
	flex-wrap: wrap;
}

.hub_list_card {
	display: flex;
	align-items: center;
	padding: 10px;
	gap: 20px;
	background: #ffffff;
	border-radius: 8px;
	margin-top: 1.2rem;
	flex-wrap: wrap;
}

.course_list_card_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #8d8d8d;
	margin-bottom: 0.3rem;
}

.course_list_card_value {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #100f0f;
	margin-bottom: 0;
}

.group_name_value_card {
	overflow-wrap: break-word;
	word-wrap: normal;
	word-break: break-word;
}

.course_divider1 {
	flex: 0.35;
}
.course_divider1.big {
	flex: 0.37;
}

.cash-position_divider1 {
	flex: 0.35;
}

.course_divider1.small {
	flex: 0.3;
}

.course_divider2 {
	flex: 0.2;
}

.course_divider2.big {
	flex: 0.25;
}

.course_divider3 {
	flex: 0.2;
}

.cash-position_divider3 {
	flex: 0.2;
}

.cash-position_divider3_big_one {
	flex: 0.4;
}

.course_divider4 {
	flex: 0.1;
}

.course_divider5 {
	flex: 0.4;
}

.group_divider3 {
	flex: 0.4;
}

.group_divider2 {
	flex: 0.1;
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.group_divider1 {
	flex: 0.1;
}

.hub_list_divider1 {
	flex: 0.3;
}

.hub_list_divider2 {
	flex: 0.5;
	padding: 0 6px;
}

.hub_list_divider3 {
	flex: 0.2;
}

.course_action_wrapper {
	padding: 8px;
	gap: 10px;
	background: #eaf3ff;
	border-radius: 60px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #0057bc;

	width: 70px;
	text-align: center;
	cursor: pointer;
}

.add_course_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	isolation: isolate;
	height: 100px;
	background: rgba(255, 255, 255, 0.2);
	border: 2px dashed #858585;
	border-radius: 8px;
	margin-bottom: 2rem;
	margin-top: 2rem;
	cursor: pointer;
}

.add_course_container2 {
	justify-content: center;
	align-items: center;
	padding: 15px;
	height: unset;
	isolation: isolate;
	background: rgba(255, 255, 255, 0.2);
	border: 2px dashed #858585;
	border-radius: 8px;
	margin-bottom: 2rem;
	margin-top: 2rem;
	cursor: pointer;
}

.add_course_text_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0.3;
}

.add_course_container:hover .add_course_text_container {
	opacity: 1;
}

.add_course_container:hover {
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border: none;
}

.add_course_container2:hover {
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border: 2x solid #ffffff;
}

.course_view_text_data {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 18px;
	color: #100f0f;
	margin-bottom: 0;
	margin-top: 1.2rem;
}

.course_view_tag_container {
	display: flex;
	flex-wrap: wrap;
	transition: 0.8s, transform 1.5s;
}

.course_view_tag_container_less {
	display: flex;
	flex-wrap: wrap;
	max-height: 73px;
	overflow: hidden;
	transition: 0.3s, transform 0.6s;
}

.course_view_tag_container_more {
	/* max-height: var(--subject-chapter-container-height); */
	max-height: 1000px;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	transition: 1s, transform 1.8s;
}

.course_view_tag_wrapper {
	padding: 4px 8px;
	gap: 6px;
	background: #f9f8f8;
	border-radius: 33px;
	margin: 0.3rem;
}

.course_view_tag_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #000000;
}

.course_view_subject_chapter_container {
	padding: 10px;
	gap: 8px;
	background: #f9f8f8;
	border-radius: 8px;
}

.course_subject_name {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	color: #000000;
}

.chapter_view_tag_wrapper {
	padding: 4px 8px;
	gap: 6px;
	background: #ffffff;
	border-radius: 33px;
	margin: 0.3rem;
}

.course_chapter_view_more {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #045aa5;
	cursor: pointer;
}

.chapter_subject_view_container {
	margin-top: 1.5rem;
}

.modal_save_confirmation_container {
	padding: 10px;
	gap: 166px;
	background: #f4f4f4;
	border-radius: 8px;
	margin-top: 1rem;
}

.modal_save_confimation_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #8d8d8d;
	margin-bottom: 0.1rem;
}

.modal_save_confimation_value {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #100f0f;
	word-break: break-word;
}

.modal_save_confirmation_wrapper {
	max-height: 300px;
	overflow: auto;
	margin-top: 0.5rem;
	padding: 0 0.5rem;
}

.batch_read_only_wrapper {
	margin-top: 2.2rem;
}

.view_data_static_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #8d8d8d;
	margin-bottom: 0;
}

.view_data_static_value {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 18px;
	color: #100f0f;
	margin-bottom: 0;
	margin-top: 1rem;
	text-transform: capitalize;
}

.batch_date_picker {
	width: 100%;
	border: 1px solid #858585 !important;
	border-radius: 6px !important;
	min-height: 40px !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px !important;
	line-height: 18px !important;
	color: #100f0f !important;
}

.ant-picker-input > input::placeholder {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px !important;
	line-height: 16px !important;
	color: #858585 !important;
}

.main_subject_container {
	margin-top: 1.5rem;
}

.subject_divider1 {
	flex: 0.5;
}

.subject_divider2 {
	flex: 0.4;
}

.subject_divider3 {
	flex: 0.1;
}

.update_hub_logo {
	display: flex;
	width: 480px;
	background-color: #fff;
	padding: 15px;
	align-items: center;
	justify-content: space-between;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0.02em;
	color: #000000;
}

.hub_list_img {
	height: 90px;
	max-width: 90%;
}

.hub_list_text {
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button_row_add_hub_alert {
	display: flex;
	justify-content: flex-end;
	padding: 0px 10px;
	gap: 20px;
}

.change_pw_input .add_hub {
	min-width: 250px;
}

.add_course_resource_text.inactive {
	color: #bc0000;
	font-weight: 400;
}

.add_course_resource_text.inactive.true {
	color: #bc0000;
	font-weight: 600;
}

.add_course_resource_text.active {
	color: #48a400;
}

.add_course_resource_text.active.true {
	font-weight: 700;
	color: #48a400;
}

.align_text_image {
	margin-top: 5px;
}

.student_filter_drop_header.big {
	font-size: 18px;
}

@media screen and (max-width: 670px) {
	.hub_list_card {
		justify-content: center;
		flex-direction: column;
	}

	.hub_list_img {
		max-width: 100%;
	}

	.update_hub_logo {
		width: 95%;
		flex-direction: column;
		gap: 20px;
	}

	.course_divider4 {
		margin-left: 30px;
		margin-top: 15px;
	}

	.group_list_card {
		/* flex-direction: column; */
		align-items: flex-start;
	}

	.group_divider1 {
		flex: 0.2;
	}

	.group_divider2 {
		flex: 0.2;
	}

	.group_divider3 {
		flex: 0.8;
	}
}

.group_name_container {
	display: flex;
	width: 100%;
	/* padding: 10px 10px; */
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	/* background-color: yellow; */
}

.group_name_header_container {
	/* padding: 6px; */
	/* background: #E9E9E9; */
	/* gap: 10px;
    border-radius: 8px;
    margin-top: 1.7rem;
    display: flex;
    width: 100%;
    font-family: 'Montserrat';
    font-style: 'SemiBold';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em; */

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #5a5a5a;
}

.group_name_data_container {
	background: #ffffff;
	border-radius: 8px;
	margin-bottom: 20px;
	padding: 20px 0px 20px 20px;
	gap: 10px;
	display: flex;
	width: 100%;
	box-shadow: 8px 8px 50px rgba(0, 0, 0, 0.1);
	flex: 1;
	max-height: 50vh;
	overflow-y: auto;
}

.group_modal_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-self: center;
	text-align: center;
	/* identical to box height, or 129% */
	color: #151616;
}

.group_modal_batches_card {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 14px;
	gap: 4px;
	background: #e7e7e7;
	border-radius: 24px;
	margin-inline: 5px;
}

.group_modal_batches_container {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.group_modal_center_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #1a1919;
}

.full_batch_list_batches_container {
	width: 100%;
	flex-wrap: wrap;
	display: block;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 6px 10px;
	gap: 20px;
	background-color: white;
	border-radius: 0px;
}

.full_batch_list_accordion_container {
	width: 100%;
	display: block;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px !important;
	gap: 10px;
	background: green;
	border-radius: 6px;
}

.full_batch_list_accordion_container2 {
	padding: 0px !important;
}

.batch_list_batch_name {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	justify-content: center;
	align-items: center;
	/* line-height: 18px; */
}

.full_batch_list_course_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 6px 10px;
	gap: 20px;
	background: #f9f9f9;
	border-radius: 0px;
}

.full_batch_list_footer {
	position: fixed;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-inline: 60px;
	/* gap: 212px; */
	height: 104px;
	background-color: white;
	width: 100%;
}

.full_batch_list_footer_col1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 1.5;
}

.full_batch_list_footer_col2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 6;
}

.full_batch_list_footer_col3 {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	flex: 1.5;
}

.full_batch_list_group_name_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	color: #8d8d8d;
}

.full_batch_list_group_name_data {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #100f0f;
}

.course_name_data {
	background: #f9f8f8;
	border-radius: 8px;
	padding: 8px 15px;
}

.selected_tags_name {
	background: #f9f8f8;
	border-radius: 50px;
	padding: 8px 15px;
	margin: 4px;
}

.faculty_selected_tags_name {
	background: #ededed;
	border-radius: 50px;
	padding: 8px 15px;
	margin: 4px;
}

.chapter_selected_tags_container {
	display: flex;
	flex-wrap: wrap;
}

.modal_save_confirmation_selected_tag {
	background-color: #ffffff;
	padding: 4px 18px;
	border-radius: 40px;
	margin: 4px;
}

.subject_scroll_container {
	height: calc(100vh - 22rem);
	overflow: auto;
	margin-right: -0.8rem;
	padding-right: 0.8rem !important;
}
.reversal_history_scroll_container {
	height: calc(100vh - 17.9rem);
	overflow: auto;
	margin-right: -0.8rem;
	padding-right: 0.8rem !important;
}

.faculty_scroll_container {
	height: calc(100vh - (22rem + 20px));
	overflow: auto;
	margin-right: -0.8rem;
	margin-top: 20px;
	padding: 10px;
	padding-top: 0px;
	background-color: #ffffff;
	padding-right: 0.8rem !important;
}

.asset_view_details {
	background-color: #fcfcfc;
	border-radius: 8px;
	padding: 20px;
	margin-left: 20px;
}

.attendance_date_picker {
	justify-content: center;
	width: 100%;
	/* border: 1px solid #BDBDBD !important; */
	border: 1px solid #858585 !important;
	border-radius: 6px !important;
	min-height: 40px !important;
	margin-bottom: 20px !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px !important;
	line-height: 18px !important;
	color: #000000 !important;
}

.attendance_date_picker .ant-picker-input > input {
	font-family: "Montserrat" !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 18px !important;
	color: #000000 !important;
}

.margin_Top {
	margin-top: 60px !important;
}

.hidden_arrows_input::-webkit-outer-spin-button,
.hidden_arrows_input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.hidden_arrows_input {
	-moz-appearance: textfield;
	width: 150px !important;
}

.hidden_arrows_input_full_width::-webkit-outer-spin-button,
.hidden_arrows_input_full_width::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.hidden_arrows_input_full_width {
	-moz-appearance: textfield;
	width: 100%;
}

.ant-select-dropdown {
	z-index: 999999992 !important;
}

.reversal_reason_dropdown {
	width: 100%;
	max-width: 350px;
}

.reversal_reason_dropdown .ant-select-selection-placeholder {
	/* antd override */
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 16px;
	color: #858585;
}

.reversal_reason_dropdown .ant-select-selector {
	/* antd override */
	border: 1px solid #858585 !important;
	border-radius: 6px !important;
	min-height: 38px !important;
}

.reversal_reason_dropdown .ant-select-selection-item {
	/* antd override */
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #000000;
	margin-top: 0.2rem;
}

.reversal_reason_dropdown .ant-select-single .ant-select-selector .ant-select-selection-item:after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder:after,
.ant-select-single .ant-select-selector:after {
	margin-top: 0.2rem;
}

.reversal_container_options {
	display: flex;
	justify-content: space-between;
}

.full-width {
	width: 100% !important;
}

.button_underline {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #0057bc;
	cursor: pointer;
	padding: 10px;
}

.sub_row {
	width: 450px;
	justify-content: flex-end;
}

.padding_left {
	padding-left: 15px;
}

.payment_details_search_button {
	background-color: #012c63;
	font-weight: 500;
	font-style: normal;
	font-family: "Montserrat";
	outline: none !important;
	color: #ffffff !important;
	height: 44px;
	border-radius: 10px;
	margin-left: 1.5rem;
	cursor: pointer;
	min-width: 95px;
}

.payment_details_search_button.disabled {
	background: #012c63 !important;
	opacity: 0.2 !important;
	color: #ffffff !important;
	cursor: not-allowed !important;
}

.payment_details_card {
	padding: 6px 25px 20px 25px;
	margin-bottom: 20px;
	background-color: #ffffff;
	border-radius: 8px;
	align-items: center;
	gap: 25px;
}

.payment_details_card_ack {
	padding: 6px 25px 40px 25px;
	margin-bottom: 20px;
	background-color: #ffffff;
	border-radius: 8px;
	align-items: center;
	gap: 25px;
}

.payment_details_card_row {
	gap: 25px;
	display: flex !important;
	align-items: flex-start;
	width: 100%;
}

.payment_details_card_row.cheque {
	align-items: flex-start;
}

.payment_details_circular_img {
	background: var(--finance-payment-type);
	border-radius: 100px;
	max-width: 35px;
	min-width: 35px;
	height: 35px;
	text-align: center;
	padding: 7px 0 7px 0;
	flex: 0.04 1;
	align-self: center;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.payment_details_student_circular_img {
	background: #2caa00;
	width: 12px;
	height: 12px;
	border-radius: 100px;
	min-width: 12px;
	align-self: center;
}

.payment_details_student_circular_img.block {
	background: transparent;
}

.payment_details_student_circular_img.inactive {
	background: red;
}

.paymanet_details_card_divider00 {
	flex: 0.05 !important;
	min-width: 40px !important;
	align-items: center;
}

.paymanet_details_card_divider0 {
	flex: 0.12 !important;
	min-width: 220px !important;
	gap: 15px;
}

.paymanet_details_card_divider1 {
	flex: 0.34 !important;
	min-width: 210px !important;
}

.paymanet_details_card_divider1.pos {
	display: flex;
	justify-content: flex-end !important;
}

.paymanet_details_card_divider2 {
	flex: 0.12 !important;
	min-width: 100px !important;
}

.paymanet_details_card_divider3 {
	flex: 0.38 !important;
	min-width: 140px !important;
	align-items: flex-start;
	gap: 10px;
}

.paymanet_details_card_divider3.cheque {
	flex: 0.35 !important;
	min-width: 180px !important;
}

.paymanet_details_card_amount {
	width: 40% !important;
	/* min-width: 100px !important; */
}

.paymanet_details_card_divider5 {
	/* flex: 0.24 !important; */
	flex: 0.34 !important;
	min-width: 200px !important;
	align-items: center;
	align-self: center;
	justify-content: space-between;
}

.paymanet_details_card_divider7 {
	flex: 0.24 !important;
	min-width: 200px !important;
	align-items: center;
	align-self: center;
	justify-content: space-between;
}

.paymanet_details_card_divider6 {
	display: none !important;
	flex: 0.22 !important;
	min-width: 170px !important;
	align-items: center;
	justify-content: space-between;
}

.payment_details_card_group1 {
	display: flex;
}

.payment_details_list_card_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #8d8d8d;
	margin-bottom: 0.3rem;
}

.pending-cheques__heading_text {
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: 0.04em;
	color: #101010;
}

.pending-cheques__amount {
	font-weight: 500;
	font-size: 14px;
	line-height: 28px;
	letter-spacing: 0.04em;
	color: #323232;
}

.pending-cheques__count {
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #8d8d8d;
}

.pending-cheques__details_container {
	margin-bottom: 20px;
	padding: 12px 8px;
	background: #ffffff;
	border-radius: 8px;
}

.pending-cheques__filter_container {
	margin-bottom: 20px;
	padding: 1.5rem;
	background: #ffffff;
	border-radius: 8px;
}

.filter-heading {
	font-weight: 500;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.02em;
	color: #0b0b0c;
}

.filter-arrow-wrapper:hover {
	cursor: pointer;
}

.cheques_apply-filter-button,
.cheques_apply-filter-button:hover,
.cheques_apply-filter-button:focus {
	padding: 6px 10px;
	gap: 10px;
	background: #eaf3ff;
	border: 1px solid #eaf3ff;
	border-radius: 6px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.02em;
	color: #0057bc;
	box-shadow: none;
}

.finance_manager-filter-button,
.finance_manager-filter-button:hover,
.finance_manager-filter-button:focus {
	padding: 12px 20px;
	gap: 10px;
	background: #012c63;
	border: 1px solid #eaf3ff;
	border-radius: 6px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.02em;
	box-shadow: none;
	border-radius: 10px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	text-align: center;
	letter-spacing: 0.02em;

	color: #ffffff;
}

.clear-filter-cheques,
.clear-filter-cheques:hover,
.clear-filter-cheques:visited {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	text-decoration-line: underline;
	color: #012c63;
	cursor: pointer;
	margin-right: 15px;
}

.cheque-filter-button-container {
	align-self: flex-end;
}

.pending-cheques__details_container:last-child {
	margin-bottom: 0px;
}

.ack_number {
	padding: 2px 10px;
	gap: 10px;
	background: #eaf3ff;
	border-radius: 8px;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #8d8d8d;
	width: fit-content;
	margin: 0px 10px;
}

.details_container_row1 {
	display: flex;
	margin-inline: 10px;
	justify-content: space-between;
}

.details_container_row_pending_cash {
	display: flex;
	margin-inline: 10px;
}

.details_container_row2 {
	margin-inline: 10px;
}

.cheque-details__col-1 {
	display: flex;
	align-self: center;
	/* width: 62%; */
	flex-grow: 1;
	justify-content: space-between;
}

.form-container__col-first {
	display: flex;
	align-self: center;
	width: 75%;
}

.form-container__col-first_second {
	display: flex;
	align-self: center;
	width: 65%;
}

.form-container__col-first.filter {
	width: 80%;
	gap: 20px;
}

.form-container__col-first_second.filter {
	width: 65%;
	gap: 20px;
}

.cheque-details__col-2,
.cheque-button-container {
	align-self: center;
	width: 15%;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #100f0f;
}

.cheque-details__label {
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #8d8d8d;
	margin-bottom: 0.3rem;
}

.payment_details_list_card_header.value.big {
	font-size: 16px;
}

.payment_details_list_card_header.value {
	font-size: 14px;
	line-height: 18px;
	color: #100f0f;
}

.payment_details_list_card_header.subvalue {
	font-size: 10px;
	line-height: 18px;
	color: #8d8d8d;
}

.payment_details_list_card_header.reversed {
	font-size: 10px;
	line-height: 18px;
	color: #ff0000;
}

.payment_details_list_card_header.underlined {
	color: #0057bc;
	text-decoration-line: underline;
	font-size: 10px;
	line-height: 15px;
	cursor: pointer;
}

.payment_details_view_button {
	background: #eaf3ff;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 8px 5px 10px;
	gap: 5px;
	border-radius: 60px;
	color: #0057bc;
	text-align: center;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	cursor: pointer;
}

.payment_details_view_button.header {
	background: #eaf3ff;
	color: #8d8d8d;
	padding: 2px 8px;
	width: 200px;
	margin-bottom: 15px;
	margin-left: 35px;
	margin-top: 1.2rem;
}

.sticky_tag {
	position: relative;
	top: -7px !important;
	right: -25px;
	float: right;
	gap: 12px;
}

.export_download {
	width: 1.6rem;
}

.export_row {
	align-items: center;
	justify-content: flex-end;
	font-size: 18px;
	font-weight: 600;
	color: #0057bc;
	gap: 5px;
	margin-top: 18px;
	margin-bottom: 10px;
}

.course_list_header.payment_details {
	border-radius: 8px;
	margin-bottom: 2rem;
}

.padding_left_payments_div {
	padding-left: 24px;
}

@media only screen and (max-width: 600px) {
	.payment_reversal_container {
		height: calc(100vh - 56px);
	}

	.payment_reversal_container2 {
		height: auto;
	}

	.sub_row {
		margin-top: 0.5rem;
		justify-content: space-between;
		width: 100% !important;
	}
}

@media only screen and (max-width: 1160px) {
	.padding_left_payments_div {
		padding-left: 0px;
	}

	.payment_details_card_row {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 12px;
	}

	.payment_details_card_row.cheque {
		gap: 10px;
	}

	.payment_details_list_card_header {
		font-size: 14px;
		line-height: 20px;
	}

	.payment_details_list_card_header.value {
		font-size: 16px;
		line-height: 20px;
	}

	.payment_details_list_card_header.subvalue {
		font-size: 12px;
		line-height: 20px;
	}

	.payment_details_list_card_header.underlined {
		font-size: 12px;
		line-height: 20px;
	}

	.payment_details_circular_img {
		min-width: 40px;
		height: 40px;
		flex: 0.2 1;
		text-align: center;
		padding: 9px 0;
		align-self: center;
	}

	.payment_details_student_circular_img.block {
		display: none !important;
	}

	.paymanet_details_card_divider00 {
		width: 100%;
		gap: 30px;
		align-items: center;
		align-self: center;
	}

	.paymanet_details_card_divider00.subrow {
		display: none !important;
	}

	.paymanet_details_card_divider0 {
		width: 100%;
		gap: 20px;
		align-items: center;
	}

	.paymanet_details_card_divider1 {
		width: 100%;
		justify-content: space-between;
	}

	.paymanet_details_card_divider1.pos {
		justify-content: flex-start !important;
	}

	.paymanet_details_card_divider3 {
		width: 100%;
		align-items: flex-start;
	}

	.paymanet_details_card_divider5 {
		width: 100%;
		gap: 30px;
		align-items: center;
	}

	.paymanet_details_card_divider7 {
		justify-content: flex-start !important;
		width: 100%;
	}

	.paymanet_details_card_divider7.block {
		display: none !important;
	}

	.paymanet_details_card_divider6 {
		display: flex !important;
		width: 100%;
		gap: 30px;
		align-items: center;
	}

	.payment_details_view_button.header {
		margin-left: 0px;
	}
}

.cheque-details__label-1 {
	display: flex;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #100f0f;
	align-items: center;
}

.cheque-details__label-2 {
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #8d8d8d;
}

.student-details-col {
	display: flex;
	flex: 0.5;
}

.cheque-details-col {
	display: flex;
	flex: 0.4;
}

.margin-right-det-col {
	margin-right: 0.8em;
}

.margin-right-det-col-cheque-first {
	margin-right: 2em;
}

.margin-left-det-col {
	margin-left: 0.8em;
}

.margin-left-det-col-cheque-first {
	margin-left: 2em;
}

.view-cheque-link,
.view-cheque-link:hover {
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	text-decoration-line: underline;
	color: #0057bc;
	cursor: pointer;
	margin-left: 10px;
	align-self: center;
}

.pending-cheques-scroll-container {
	height: calc(100vh - 14.7rem);
	overflow: auto;
	margin-right: -0.8rem;
	padding-right: 0.8rem !important;
}

.accountant_dashboard_scroll_container {
	height: calc(100vh - (87px + 30px + 142px + 101.39px + 20px + 30px));
	overflow: auto;
	margin-right: -0.8rem;
	padding-right: 0.8rem !important;
}

.confirm_cash_scroll_container {
	height: calc(100vh - (87px + 30px + 20px + 52px + 46px + 20px + 30px));
	overflow: auto;
	margin-right: -0.8rem;
	padding-right: 0.8rem !important;
}

.cash_deposit_list_scroll_container {
	height: calc(100vh - (87px + 30px + 69px + 20px + 145.39px + 22px));
	overflow: auto;
	margin-right: -0.8rem;
	padding-right: 0.8rem !important;
}

.pending-cheques-scroll-container.payment {
	margin-top: 0px !important;
}

.update-cheque__heading {
	margin-top: 8px;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.02em;
	color: #8d8d8d;
}

.cheque-form-container {
	display: flex;
	justify-content: space-between;
}

.cheque-status-select-container {
	width: 27.33%;
}

.cheque-status-select-container.filter {
	width: 31%;
}

.cheque_filter_drop_header {
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #101010;
}

.cheque_filter_drop_header_inactive {
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #b7b7b7;
}

.add_tab_container {
	flex-direction: row;
	display: flex;
	width: 80%;
}

.add_tab_wrapper {
	display: flex;
	width: 100%;
	flex-direction: column;
}

.submit-btn-pending-cheque,
.submit-btn-pending-cheque:hover,
.submit-btn-pending-cheque:focus {
	padding: 10px 25px;
	gap: 10px;
	background: #012c63;
	border: 1px solid #012c63;
	border-radius: 8px;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.02em;
	color: #ffffff;
	box-shadow: none;
	outline: none;
}

.finance-loader-container {
	height: calc(100vh - 9rem);
	/* background-color: #ffffff !important; */
	text-align: center;
}

.finance-loader-gif {
	max-width: 400px;
	margin-top: 40px;
}

.finace-loader-tagline {
	margin-top: -6rem;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: 0.04em;
	color: #000000;
}

.view_cheque_modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	box-sizing: 24;
	width: 40%;
	padding: 40px;
	border-radius: 5px;
	overflow: auto;
	max-height: 600px;
}

.view_question_modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	width: 80%;
	padding: 10px 40px;
	padding-bottom: 30px;
	border-radius: 5px;
	overflow: auto;
	max-height: 90vh;
	font-weight: 500;
	color: #5b5b5b !important;
}

.selected-filters-cheque-col-2 {
	/* margin-right: 70px; */
	width: 50%;
	justify-content: end;
	align-items: center;
}

.selected-filters-cheque-row {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
}

.cheque-selected-filter {
	padding: 6px 10px;
	gap: 10px;
	background: #eaf3ff;
	border-radius: 80px;
	font-size: 14px;
	line-height: 18px;
	color: #000000;
	margin: auto 10px;
}

.flex-wrapper-cheque {
	display: flex;
	align-items: center;
}

.padding_top {
	padding-top: 20px;
}

.date-row-cheque {
	width: 45%;
	align-items: center;
}

.filter-heading-light {
	font-weight: 500;
	font-size: 10px;
	line-height: 18px;
	color: #b6b6b6;
}

.content_width {
	width: 40% !important;
	max-width: 350px;
}

.benifits_tag {
	background-color: #ececec;
	width: max-content;
	padding: 4px 10px;
	gap: 10px;
	border-radius: 60px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #101010;

	display: flex;
	align-items: center;
	justify-content: center;
}

.delIconContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px;
	gap: 10px;
	width: max-content;
	border: 1px solid #ffc1c1;
	border-radius: 6px;
	margin-top: 20px;
	cursor: pointer;
}

.addMoreBenifits {
	display: flex;
	justify-content: center;
	align-items: center;
	width: max-content;
	gap: 8px;
	cursor: pointer;
}

.onlineModalContainer {
	padding: 20px 30px;
}

.onlineModalText {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.02em;
	color: #404040;
	max-width: 280px;
	margin-bottom: 10px;
}

@media screen and (max-width: 991.98px) {
	.delIconContainer {
		margin-top: 0px;
	}

	.academic_details_header_container {
		padding-inline: 0px;
	}

	.date-row-cheque {
		width: 100%;
	}

	.add_tab_container {
		width: 90%;
	}

	.add_tab_container {
		flex-wrap: wrap;
	}

	.form-container__col-first.filter {
		width: 100%;
	}

	.form-container__col-first_second.filter {
		width: 100%;
	}

	.selected-filters-cheque-col-2 {
		/* margin-right: 70px; */
		width: 100%;
	}

	.selected-filters-cheque-col-1 {
		/* margin-right: 70px; */
		width: 100%;
	}

	.cheque-selected-filter {
		margin-bottom: 5px;
	}

	.flex-wrapper-cheque {
		flex-wrap: wrap;
	}

	.selected-filters-cheque-col-2 {
		margin-right: 40px;
		margin-block: 15px;
	}

	.selected-filters-cheque-col-1 {
		margin-right: 40px;
		margin-block: 15px;
	}

	.selected-filters-cheque-row {
		display: block;
	}

	.cheque-form-container {
		display: block;
	}

	.cheque-status-select-container {
		width: 100%;
	}

	.cheque-status-select-container.filter {
		width: 100%;
	}

	.cheque-details__col-1,
	.cheque-details__col-2,
	.cheque-button-container,
	.form-container__col-first,
	.form-container__col-first_second {
		display: block;
		width: 100%;
	}

	.cheque-details-col,
	.student-details-col {
		display: block;
	}

	.margin-right-det-col {
		margin-right: 0em;
	}

	.margin-right-det-col-cheque-first {
		margin-right: 0em;
	}

	.margin-left-det-col {
		margin-left: 0em;
	}

	.margin-left-det-col-cheque-first {
		margin-left: 0em;
	}

	.view_cheque_modal {
		width: 70%;
		padding: 25px;
	}

	.view_question_modal {
		width: 90%;
		padding: 25px;
	}

	.content_width {
		width: 90% !important;
	}
}

@media screen and (max-width: 598.98px) {
	.padding_top {
		padding-top: 5px;
	}

	.payment_details_list_card_header.subvalue {
		font-size: 10px;
		line-height: 20px;
	}

	.selected-filters-cheque-col-2 {
		margin-right: 10px;
	}

	.selected-filters-cheque-col-1 {
		margin-right: 10px;
	}

	.finace-loader-tagline {
		font-size: 12px;
	}

	.finance-loader-gif {
		max-width: 275px;
	}

	.finance-loader-container {
		height: calc(100vh - 5.7rem);
		background-color: #ffffff !important;
	}

	.details_container_row1 {
		display: block;
		margin: 0px 10px;
	}

	.details_container_row_pending_cash {
		display: block;
		margin: 0px 10px;
	}

	.details_container_row2 {
		margin: 10px;
	}

	.view_cheque_modal {
		width: 90%;
		padding: 20px;
	}

	.student_installment_missed {
		margin-bottom: 0rem;
		justify-content: unset;
		margin-top: 10px;
	}

	.student_detail_email_text {
		font-size: 10px;
	}
}

.display-none {
	display: none !important;
}

.hub-container {
	margin-top: 40px;
	margin-left: 48px;
	margin-right: 34px;
}

.margin_Bottom {
	margin-bottom: 20px !important;
}

.bank_deposit_modal_heading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: 0.02em;
	color: #000000;
}

.bank_deposit_modal_heading.small {
	font-size: 16px;
	line-height: 50px;
	font-weight: 500;
}

.locked_content_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #bc0000;
}

.locked_content_wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	gap: 10px;
	width: 146px;
	height: 44px;
	background: #ffe9e9;
	border-radius: 8px;
	margin-left: 2rem;
}

.unlocked_content_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #50b600;
}

.unlocked_content_wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	gap: 10px;
	width: 146px;
	height: 44px;
	background: #eaffda;
	border-radius: 8px;
	margin-left: 2rem;
}

.hostel_tag_wrapper {
	width: fit-content;
	background: #d7fff3;
	padding: 4px 8px;
	border-radius: 80px;
	margin-top: 0.2rem;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.02em;
	color: #00c6ba;
}

.faculty_header_counter {
	background: #eaf3ff;
	border-radius: 50px;
	padding: 5px 15px;
	gap: 10px;
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 0.02em;
	color: #012c63;
}

.faculty_image_box {
	border-radius: 50%;
	margin: 5px 3px;
	cursor: pointer;
	background-repeat: no-repeat;
}

.faculty-image-data-container {
	display: flex;
	align-items: center;
	min-width: 360px;
}

.faculty-image-add-helper-text {
	font-weight: 500;
	font-size: 10px;
	line-height: 18px;
	color: #8e8e8e;
}

.faculty_gender_options_container {
	display: flex;
	justify-content: flex-start;
	gap: 10px;
	flex-wrap: wrap;
	min-width: 270px;
}

.faculty_gender_option {
	background: #f9f9f9;
	border-radius: 20px;
	padding: 4px 10px;
	gap: 10px;
	margin-inline: 5px;
	cursor: pointer;
	font-weight: 400;
	font-size: 16px;
	color: #012c63;
}

.selected_faculty_gender_option {
	background: #daeaff;
	border-radius: 20px;
	padding: 4px 10px;
	gap: 10px;
	margin-inline: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 16px;
	color: #012c63;
}

.faculty-flex-container {
	display: flex;
	/* align-items: center; */
	flex-wrap: wrap;
}

.margin-top-3-rem-web {
	margin-top: 3rem;
}

@media screen and (max-width: 991px) {
	.faculty-flex-container {
		display: block;
	}

	.margin-top-3-rem-web {
		margin-top: 0rem;
	}
}

@media screen and (max-width: 551px) {
	.faculty-image-data-container {
		align-items: center;
		min-width: unset;
	}
}

.payment_reversed_modal_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
}

.payment_details_reversal_close_button {
	background-color: #012c63;
	font-weight: 500;
	font-style: normal;
	font-family: "Montserrat";
	outline: none !important;
	color: #ffffff !important;
	height: 38px;
	border-radius: 10px;
	margin-left: 1.5rem;
	cursor: pointer;
	min-width: 130px;
	float: right;
}

.payment_details_reversal_wrapper {
	background-color: #f5f5f5;
	border-radius: 8px;
}

.payment_details_reversal_wrapper .payment_details_card {
	background-color: #f5f5f5 !important;
}

.display-none {
	display: none !important;
}

.edit_student_container {
	display: flex;
	padding: 20px;
	flex-direction: column;
}

.tab_container {
	display: flex;
	width: 100%;
}

.tab_card_div {
	display: flex;
	flex-wrap: wrap;
	border-radius: 8px;
}

.card-container {
	margin: 0;
	display: flex;
	width: 100%;
}

.card-container > .ant-tabs-card .ant-tabs-content {
	/* height: 120px; */
	margin-top: -16px;
}

.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
	padding: 16px;
	background: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-nav::before {
	display: none;
}

.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
	background: transparent;
	border-color: transparent;
}

.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
	background: #fff;
	border-color: #fff;
}

#components-tabs-demo-card-top .code-box-demo {
	padding: 24px;
	overflow: hidden;
	background: #f5f5f5;
}

[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-content {
	height: 120px;
	margin-top: -8px;
}

[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab {
	background: transparent;
	border-color: transparent;
}

[data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
	background: #000;
}

[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
	background: #141414;
}

[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
	background: #141414;
	border-color: #141414;
}

.course_tab_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	/* or 100% */

	display: flex;
	align-items: center;
	text-align: center;

	color: #000000;
}

.tab_text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.tab_number_text_inactive {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	margin-top: 20px;
	/* identical to box height, or 112% */
	text-align: center;
	color: #b7b7b7;
}

.tab_number_text_active {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	margin-top: 20px;

	/* identical to box height, or 112% */
	text-align: center;
	color: #000000;
}

button.addbtn3 {
	/* position: absolute; */
	/* right: -40px; */
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	border-radius: 8px;
	color: #e1e1e1;
	background: #fff;
	transform: translateY(86%);
	/* transform: translateX(10%); */
	/* top: 50%; */
	transition: 0.5s all ease;
	height: 40px;
	width: 40px;
	margin-left: 10px;
	font-size: 22px;
}

button.addbtn3:hover {
	color: #ff0000;
	border-color: #ff0000;
}

.add_tab_back {
	padding: 0px 20px;
	gap: 10px;
	background: #eff6ff;
	border-radius: 8px;
	outline: none !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #012c63;

	height: 40px;
	border: 1px solid rgba(1, 44, 99, 0.2);
}

.add_tab_next {
	padding: 0px 25px;
	gap: 10px;
	background: #012c63;
	border-radius: 8px;
	outline: none !important;
	height: 40px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 27px;
	color: #ffffff !important;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.add_tab_next .disabled {
	cursor: block;
}

.payment_details_reversal_wrapper .payment_details_card_ack {
	background-color: #f5f5f5 !important;
}

.assign_reviewer_container {
	/* display: flex; */
	/* justify-content: center; */
	/* align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 21px; */
	padding: 0 4rem;
}

.assign-reviewer_question-count {
	background: #eaf3ff;
	border-radius: 60px;
	padding: 6px 10px;
	gap: 5px;
	font-weight: 500;
	font-size: 12px;
	text-align: center;
	color: #0057bc;
}

.assign_reviewer_container_pending:hover .assign-reviewer_question-count {
	background: #fdc493;
	border-radius: 60px;
	padding: 6px 10px;
	gap: 5px;
	font-weight: 500;
	font-size: 12px;
	text-align: center;
	color: #bc7400;
}

.disabled-content {
	opacity: 0.4;
	cursor: not-allowed;
}

.tab-level-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.show-asigned-container {
	align-items: center;
	padding: 4px;
	gap: 10px;
	background: #f8fbff;
}

.show-asigned-text {
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #012c63;
}

.filter-container-assign-ques {
	padding: 20px;
}

.filter-web-margin {
	margin-top: 10px;
}

.filter-heading-col-assign-ques {
	margin-inline: 15px;
}

.tab-level-tag {
	padding: 6px 4px;
	gap: 10px;
	color: #012c63;
	background: #edf5ff;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	font-size: 14px;
	text-align: center;
}

.assign-question-checkbox {
	width: 20px;
	height: 20px;
}

.assign-question-checkbox-label {
	font-size: 18px;
	line-height: 22px;
	color: #100f0f;
}

.question-card-assign-reviewer {
	padding: 6px 20px;
	gap: 34px;
	background: #f8f8f8;
	border-radius: 8px;
	margin-block: 10px;
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: space-between;
}

.difficulty-level-indicator {
	width: 40px;
	height: 40px;
	background: #eaf3ff;
	border-radius: 50%;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #0057bc;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
}

.unassigned-faculty-value {
	color: rgba(140, 140, 140, 0.572);
}

.bottom_bar-assign-reviewer {
	padding: 33px 20px;
	position: sticky;
	bottom: 7px;
	background: #ffffff;
	box-shadow: 0px -20px 50px rgba(0, 0, 0, 0.06);
}

.bottom_bar-inside {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.selected-classes-container {
	padding: 5px 16px;
	gap: 4px;
	background: #f9f9f9;
	border: 1px solid #e2e2e2;
	border-radius: 24px;
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	color: #100f0f;
}

.assign-reassign-button,
.assign-reassign-button:hover,
.assign-reassign-button:focus {
	padding: 13px 35px;
	gap: 10px;
	background: #012c63;
	border-radius: 10px;
	font-weight: 600;
	font-size: 14px;
	text-align: center;
	letter-spacing: 0.02em;
	color: #ffffff;
}

.question-options-modal-container {
	width: 48%;
}

.question_modal_question {
	padding: 10px 13px;
	gap: 10px;
	border: 1px solid #dfdfdf;
	border-radius: 8px;
	font-size: 16px;
	letter-spacing: 0.005em;
	color: #5b5b5b;
}

.question_modal_question.correct_option {
	padding: 10px 13px;
	gap: 10px;
	background: #e3ffda;
	border: 1px solid #dfdfdf;
	border-radius: 8px;
	letter-spacing: 0.005em;
	color: #5b5b5b;
}

.question-modal-row {
	display: flex;
	justify-content: space-between;
}

.question-modal-properties-container {
	display: flex;
	width: 65%;
	justify-content: space-between;
}

.question-modal-property {
	width: 25%;
}

.question-modal-property:first-child {
	width: 40%;
}

.question-modal-tag {
	font-weight: 500;
	font-size: 16px;
	color: #929292;
}

.question-modal-tag-tooltip-container {
	align-items: center;
	padding: 4px 6px;
	gap: 10px;
	width: 45px;
	height: 38px;
	background: #f6f6f6;
	border-radius: 57px;
	letter-spacing: 0.014em;
	font-size: 15px;
	color: #151516;
	text-align: center;
	justify-content: center;
}

.question-modal-difficulty-level {
	background-color: #ffffff;
	border: 1px solid #1b3687;
	width: 30px;
	height: 30px;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 50%;
	font-size: 16px;
	color: #1b3687;
	font-weight: 500;
}

.assign-reviwer-row-bordered {
	border: 1px solid #000000;
	padding: 10px 20px;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
}

.assign-reviewer-text-box {
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	align-items: center;
	letter-spacing: 0.02em;
	color: #000000;
}

.assign-reviewer-flexbox {
	display: flex;
	justify-content: space-between;
}

.small-width-mobile {
	width: 40%;
}

.add_course_text_container2 {
	display: flex;
	flex-direction: row !important;
	align-items: center;
	justify-content: center;
	opacity: 0.3;
}

.backtop_container {
	background-color: white !important;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.23));
	display: flex;
	justify-content: center;
	align-items: center;
}

.backtop_img {
	width: 2rem;
	margin-top: -0.3rem;
}

@media screen and (max-width: 850px) {
	.assign_reviewer_container {
		justify-content: center;
	}

	.small-width-mobile {
		width: 100%;
	}

	.assign-reviewer-flexbox {
		display: block;
	}

	.assign-reviwer-row-bordered {
		display: block;
	}

	.question-modal-properties-container {
		display: block;
		width: 100%;
	}

	.question-modal-property {
		width: 100%;
	}

	.question-options-modal-container {
		width: 100%;
	}

	.question-modal-row {
		display: block;
	}

	.question-card-assign-reviewer {
		padding: 10px;
		display: block;
	}

	.bottom-bar-assign-reviewer {
		width: auto;
	}

	.bottom_bar-inside {
		display: block;
	}
}

@media screen and (max-width: 551px) {
	.filter-web-margin {
		margin-top: 5px;
	}

	.filter-heading-col-assign-ques {
		margin-inline: 0px;
	}
}

.backtop_container {
	background-color: white;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.23));
	display: flex;
	justify-content: center;
	align-items: center;
}

.backtop_img {
	width: 2rem;
	margin-top: -0.3rem;
}

.ant-back-top {
	right: 60px !important;
}

.antd-placeholder-tag {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 16px;
	color: #858585;
	margin-left: 0.7rem;
}

.select_component_wrapper {
	display: flex;
	flex-wrap: wrap;
}

.select_component_text_wrapper {
	padding: 6px 10px;
	gap: 10px;
	background: #fafafa;
	border-radius: 80px;
	margin: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
}

.select_component_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #100f0f;
}

.batch_center_wrapper {
	box-sizing: border-box;

	/* Auto layout */

	padding: 20px;
	gap: 20px;

	background: #ffffff;
	border-radius: 8px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 33px;
	/* identical to box height, or 150% */

	color: #000000;

	margin-top: 20px;
}

.batch_center_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	/* identical to box height, or 150% */

	letter-spacing: 0.02em;

	color: #000000;
}

.batch_center_card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 20px;
	gap: 20px;

	/* width: 1285px;
    height: 195px; */

	background: #f7f7f7;
	border-radius: 8px;
}

.finance_charge_payment_detail {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #8d8d8d;
	margin-bottom: 0;
	margin-top: 0.2rem;
	white-space: nowrap;
}

.mycrm_nowrap {
	white-space: nowrap;
}

.cash_deposit_card_container {
	display: flex;
	background: #ffffff;
	border-radius: 8px;
	justify-content: space-around;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 20px;
	gap: 20px;
	margin-bottom: 20px;
}

.cash_deposit_card_column {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1;

	min-width: 120px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 18px;
	gap: 4px;
	color: #100f0f;

	padding: 5px;
}

.cash_deposit_card_column_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #8d8d8d;
}

.cash_deposit_card_column_header2 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #8d8d8d;
}

.cheque-details__label-1-disabled {
	display: flex;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #100f0f45;
	align-items: center;
}

.cash-details-status-approved {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100px;
	padding: 3px;
	gap: 10px;
	background: #e1ffc9;
	border-radius: 107px;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #53bc00;
	font-weight: 500;
	text-transform: capitalize;
}

.cash-details-status-rejected2 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100px;
	padding: 3px;
	gap: 10px;
	background: #ffe8e8;
	border-radius: 107px;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #bc0000;
	font-weight: 500;
	text-transform: capitalize;
}

.cash_deposit_card_column_header3 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #100f0f;
}

.link_button {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 14px;
	text-align: center;
	color: #0057bc;
	border: none;
	background-color: transparent;
}

.status_button_pending {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100px;
	padding: 3px;
	gap: 10px;
	background: #ffe5d3;
	border-radius: 107px;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #f47b26;
	font-weight: 500;
	text-transform: capitalize;
}

.cash-details-status-pending {
	padding: 6px 20px;
	gap: 10px;
	height: 30px;
	width: 94px;

	background: #ffe5d3;
	border-radius: 107px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.02em;
	color: #f47b26;
	text-transform: capitalize;
}

.status_button_rejected {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 85px;
	padding: 3px;
	gap: 10px;
	background: #ffe8e8;
	border-radius: 107px;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #bc0000;
	font-weight: 500;
	text-transform: capitalize;
}

.cash-details-status-rejected {
	padding: 6px 20px;
	gap: 10px;

	height: 30px;
	width: 94px;

	background: #ffe8e8;
	border-radius: 107px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	/* identical to box height, or 112% */

	letter-spacing: 0.02em;

	/* Error/Defalt */

	color: #bc0000;
	text-transform: capitalize;
}

.status_button_confirmed {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 85px;
	padding: 3px;
	gap: 10px;

	height: 30px;
	width: 94px;

	background: #e1ffc9;

	border-radius: 107px;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #53bc00;

	font-weight: 500;
	text-transform: capitalize;
}

.cash-details-header {
	padding: 10px 20px;
	background: #ffffff;
	border: 1px solid #0000007a;
	border-radius: 8px;
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 20px;
}

.heading-header-light {
	color: #8d8d8d;
	font-weight: 500;
}

.cash-not-confirmed-container {
	padding: 20px;
	gap: 20px;
	background: #ffffff;
	border-radius: 8px;
}

.pending-cheques__details_container-two {
	margin-bottom: 20px;
	padding: 12px 8px;
	background: #ffffff;
	border-radius: 8px;
	border: 1px solid #aeaeae;
}

.pending-cheques__details_container-two:last-child {
	margin-bottom: 0px;
}

.cash-not-confirmed-inside-container {
	padding: 30px 20px;
	padding-top: 0px;
	gap: 20px;
	background: #f3f3f3;
	border-radius: 8px;
	margin-top: 20px;
}

.export-btn-filter,
.export-btn-filter:hover,
.export-btn-filter:focus {
	padding: 7px 20px;
	gap: 16px;
	background: #f8f8f8;
	border-radius: 8px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.02em;
	color: #0057bc;
	margin: 10px;
}

.cash_position_details-container {
	padding: 15px;
	gap: 100px;
	border: 1px solid #aeaeae;
	border-radius: 8px;
	margin-bottom: 20px;
}

.cash_position_details-container:first-child {
	background-color: #ffffff !important;
	padding: 15px;
}

.cash_position_details-container:last-child {
	margin-bottom: 0px;
}

.selected-filter-tag-look {
	padding: 6px 10px;
	gap: 30px;
	background: #e9e9e9;
	border-radius: 60px;
	position: relative;
	top: -20px;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #100f0f;
}

.confirm_cash_status_bar_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	margin-bottom: 20px;

	background: #ffffff;
	border: 1px solid #000000;
	border-radius: 8px;
}

.confirm_cash_status_bar_column {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 18px;
	/* identical to box height, or 112% */

	text-align: center;

	/* Black */

	color: #100f0f;
}

.confirm_cash_status_bar_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	padding-right: 4px;
	color: #8d8d8d;
}

.cash_not_confirmed_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px;
	gap: 20px;

	/* White */
	background: #ffffff;
	border-radius: 8px;
}

.accounts_filter_drop_header {
	/* Text Box/ Header */
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height, or 129% */

	text-align: start;

	/* Black/Primary */

	color: #101010;
}

.checkbox_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */
	/* Black */
	color: #100f0f;
}

.confirm_cash_counselor_container {
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 10px 20px;
	gap: 10px;

	width: 100%;

	border: 1px solid #aeaeae;
	border-radius: 8px;
}

.confirm_cash_counselor_column1 {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 3;
	gap: 4px;
	padding: 10px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height, or 129% */

	/* Black */

	color: #100f0f;
}

.confirm_cash_counselor_column2 {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1.5;

	gap: 4px;
	padding: 10px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height, or 129% */

	/* Black */

	color: #100f0f;
}

.confirm_cash_counselor_column3 {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 4;

	gap: 4px;
	padding: 10px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height, or 129% */

	/* Black */

	color: #100f0f;
}

.confirm_cash_counselor_row {
	display: flex;
	justify-content: flex-start;
	/* align-items: center; */
	width: 100%;
	flex-wrap: wrap;
	/* background-color: #038200; */
}

.confirm_cash_header2 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	/* identical to box height, or 150% */

	/* Black */

	color: #100f0f;
}

.confirm_cash_checkbox_div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.confirm_cash_checkbox_div2 {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	padding-left: 20px;
}

.confirm_cash_checkbox_div3 {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 40px;
}

.confirm_input_container {
	/* display: flex; */
	/* flex-direction: column; */
	width: 300px;
	margin-bottom: 10px;
}

.confirm_input_container1 {
	/* display: flex; */
	/* flex-direction: column; */
	width: 310px;
	margin-bottom: 10px;
}

.current_cash_headerbar_container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 20px;
	gap: 10px;

	background: #ffffff;
	border-radius: 8px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */

	/* Black */

	color: #100f0f;
}

.current_cash_header_row {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	gap: 20px;
}

.current_cash_col_1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 10px 20px;
	gap: 4px;

	background: #daebff;
	border-radius: 8px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	/* width:fit-content; */

	/* Black */

	color: #100f0f;
	/* min-width: 120px; */
}

.current_cash_col_1_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 29px;
	flex-direction: row;
	/* Black */
	color: #100f0f;
	white-space: pre;
	margin-bottom: 0;
}

.current_cash_col_2 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	/* background-color: pink; */
	/* padding-left: 20px; */
	overflow-y: auto;
}

.current_cash_center_list_row {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	/* width: 100%; */
	/* padding-inline:-10px; */
	/* background-color: green; */
	overflow-y: auto;
}

.current_cash_center_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 10px 20px;
	gap: 4px;

	background: #f9f9f9;
	border-radius: 8px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	/* width:fit-content; */

	/* Black */

	color: #100f0f;
	margin-inline: 10px;

	white-space: nowrap;
}

.current_cash_arrow_buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 10px 20px;
	gap: 4px;

	border: none;
	height: 69px;

	background: #f9f9f9;
	border-radius: 8px;
	text-transform: capitalize;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.current_cash_center_list_row::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.current_cash_center_list_row {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.cash_info_row_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	/* padding: 20px;
    gap: 20px;
    background: #F8F8F8;
    border-radius: 8px;
    margin-bottom: 10px; */
}

.cash_info_card_container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	padding: 20px;
	/* gap: 20px;  */
	background: #f8f8f8;
	border-radius: 8px;
	margin-bottom: 10px;
	/* flex-wrap: wrap; */
}

.max_count_tooltip_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 15px;
	text-align: center;
	color: #ffffff;
}

.max_count_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	color: #858585;
	padding-top: 5px;
}

.dropdown_count_tag {
	/* padding: 10px;
    gap: 10px;
    color: #012C63;
    background: #EDF5FF;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    font-size: 14px;
    text-align: center;
    justify-content: center;
    align-items: center; */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	gap: 10px;
	background-color: #eaf3ff;
	border-radius: 60px;
	width: 40px;
	height: 22px;
	color: #0057bc;
}

.accordion_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	/* background-color: orange; */
}

.accordion_row {
	margin-top: 21px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
	padding-left: 20px;
	/* background-color: #00C6BA; */
}

.helper_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height, or 125% */
	color: #101010;
	margin-top: 4px;
}

.modal_div {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.modal_item_list_button {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 28px;
	/* identical to box height, or 175% */

	text-align: left;
	letter-spacing: 0.02em;

	color: #0e0d0d;
	/* width: 100%; */
	cursor: pointer;
	background-color: transparent;
	border: none;
	/* margin: 5px; */
	padding: 0.5rem 2rem;
	border-bottom: 1px solid #f3f3f3;
	width: 100%;
}

.accountant_dashboard_filter_container {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	/* align-items: flex-end; */
	flex-wrap: wrap;
	background-color: #ffffff;
	border-radius: 12px;
	padding: 10px;
	margin-top: 20px;
}

.cash_deposit_confrim_button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 36px;
	gap: 10px;

	height: 55px;

	background: #012c63;
	border-radius: 10px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	/* identical to box height */

	text-align: center;
	letter-spacing: 0.02em;

	color: #ffffff;
}

.modal_text_14 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height, or 129% */

	color: #8d8d8d;
}

.modal_text_16 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 18px;
	/* identical to box height, or 112% */
	/* Black */
	color: #100f0f;
}

.modal_text_18 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	/* or 150% */

	letter-spacing: 0.02em;

	color: #000000;
}

.modal_text_18_g {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 27px;
	/* identical to box height, or 150% */

	letter-spacing: 0.02em;

	color: #000000;
}

.cash_deposit_card_column2 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 10px;
	gap: 10px;
	margin: 5px;
	height: 36px;
	background: #eaf3ff;
	border-radius: 60px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #0057bc;
}

.marker {
	width: 6px;
	height: 6px;
	background-color: red;
	border-radius: 50%;
	display: inline-flex;
	align-self: center;
	margin-bottom: 2px;
	margin-right: 1px;
}

.redirection_page_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 87px - 40px -40px) !important;
	margin: 48px 40px;
	padding: 10px;
	background: #ffffff;
	border-radius: 8px;
}

.cash_deposit_rejected_text {
	color: red;
	cursor: pointer;
}

.helper_text2 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.02em;
	color: #505050;
}

.cash-position_divider3_big_one .selected-filter-tag-look {
	width: 90%;
	justify-content: space-around;
}

.reversal-flag-text {
	color: #8d8d8d;
	font-size: 10px;
	margin-left: 5px;
}

.reversal-flag-div {
	background: #f6f6f6;
	border-radius: 8px;
	padding: 4px 8px;
	/* width: 110%; */
	display: flex;
	align-items: center;
	margin-left: 8.2rem;
	position: relative;
	top: -1rem;
}

.reversal-flag-div-fm {
	background: #f6f6f6;
	border-radius: 8px;
	padding: 4px 8px;
	width: fit-content;
	display: flex;
	align-items: center;
	position: relative;
	top: -0.5rem;
	margin-left: 5rem;
}

.reversal-flag-cash-recon {
	width: 12px;
	height: 12px;
	margin-left: 3px;
}

@media (max-width: 551.98px) {
	.confirm_cash_counselor_row {
		display: block;
	}

	.confirm_cash_status_bar_container {
		display: block;
	}
}

@media (max-width: 991.98px) {
	.cash_position_details-container {
		padding: 8px;
	}

	.cash-position_divider3_big_one .selected-filter-tag-look {
		width: 130%;
		margin-left: -10%;
	}

	.disp-mob-none {
		display: none !important;
	}
}

.toggle_centre_modal {
	background-color: white;
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.23));
	border-radius: 7px;
	max-height: 300px;
	overflow: auto;
}

.accountant_filter_header {
	position: absolute;
	right: 5rem;
	z-index: 1;
}

.accountant_filter_applied {
	margin-top: -1.2rem;
}

.update_deposit_slip {
	display: flex;
	width: 150px;
	background-color: #fff;
	padding: 15px;
	align-items: center;
	/* justify-content: space-between; */
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0.02em;
	color: #000000;
}

.selected-filters-deposit-row {
	display: flex;
	margin-top: 15px;
}

.selected-filters-cheque-col-1 {
	justify-content: end;
	align-items: center;
}

.slip_date_margin {
	margin-left: 5rem;
}

.padding_top1 {
	padding-top: 23px;
}

.finance_manager-filter-button-cash-recon,
.finance_manager-filter-button-cash-recon:hover,
.finance_manager-filter-button-cash-recon:focus {
	padding: 10px 25px;
	gap: 10px;
	background: #eaf3ff;
	/* border: 1px solid #EAF3FF; */
	border-radius: 6px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.02em;
	box-shadow: none;
	border-radius: 10px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	text-align: center;
	letter-spacing: 0.02em;

	color: #0057bc;
}

@media (max-width: 550px) {
	.current_cash_header_row {
		display: block;
		width: 100%;
	}

	.current_cash_col_2 {
		margin-top: 20px;
	}
}
.student_search_input.lead {
	border: none;
	height: 60px;
	border-radius: 8px;
	max-width: 500px;
	width: 100%;
	padding: 1px 1px 1px 55px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #000000;
	background-color: #ffffff;
}
.lead_search_button {
	background-color: #012c63;
	font-weight: 600;
	font-style: normal;
	font-family: "Montserrat";
	outline: none !important;
	color: #ffffff !important;
	height: 60px;
	border-radius: 8px;
	margin-left: 1.5rem;
	cursor: pointer;
	min-width: 117px;
	font-size: 18px;
}
.lead_search_button.small {
	height: 45px;
	border-radius: 10;
	font-size: 15px;
	min-width: 110px;
	align-self: center;
	margin-right: 10px;
}
.lead_search_button.disabled {
	background: #012c63 !important;
	opacity: 0.2 !important;
	color: #ffffff !important;
	cursor: auto !important;
}
.student_search_image_lead {
	position: relative;
	left: 40px;
	width: 2rem;
	top: -1.1em;
}
.lead_search_input_border {
	border: 1px solid #858585 !important;
}
.add_lead_header {
	padding: 1.5rem;
	background-color: #ffffff;
	border-radius: 8px;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 20px;
}
.student_free {
	width: 80px;
	height: 20px;
	position: relative;
	background: #f6eadc;
	color: #70573b;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.02em;
	padding: 2px 0.6rem;
}

.student_free:after {
	content: "";
	position: absolute;
	bottom: 0;
	width: 0;
	height: 0;
	border-right: 10px solid #ffffff;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	right: -1px;
}
.lead_scroll_container {
	height: calc(100vh - 26rem);
	overflow: auto;
	margin-right: -0.8rem;
	padding-right: 0.8rem !important;
}
.fee_details_form {
	width: 90%;
	margin: 0 auto;
}
.student_otp_modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	box-sizing: 24;
	width: 550px;
	padding: 35px 40px;
	border-radius: 5px;
	overflow: auto;
	max-height: 600px;
}
.student_otp_Heading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 23px;
	color: #000000;
}
.student_otp_subHeading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 18px;
	color: #a6a9aa;
}

.verify_otp_button {
	padding: 10px 20px;
	gap: 10px;
	background: #012c63;
	border-radius: 8px;
	outline: none !important;
	width: 100%;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 23px;
	color: #ffffff !important;
}

.resent_otp {
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 18px;
	color: rgba(1, 44, 99, 0.35);
	cursor: block;
}

.resent_otp.active {
	color: #012c63;
	cursor: pointer;
}

.confirm_cash_fm_view {
	padding-left: 50px;
}

.cash_deposit_upload_deposit_edit {
	color: #0057bc;
}

.cash_deposit_rupee {
	color: #858585;
	position: relative;
	top: -3.25rem;
	font-size: 1.7rem;
	left: 1rem;
	font-weight: 600;
}

.cash_deposit_rupee_input {
	padding-left: 2.5rem !important;
}

.active_tab {
	border: 1.5px solid #d9d9d9 !important;
}

.border-bottom-none {
	border-bottom: none !important;
}

.d-none {
	display: none !important;
}

.flex-grow-1 {
	flex-grow: 1;
}

.justify-end {
	justify-content: flex-end;
}

.menu_container {
	padding: 10px;
	border-radius: 8px !important;
	margin-top: 26px;
}

.menu_item {
	color: #afafaf !important;
	font-family: "Montserrat" !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 21px !important;
	letter-spacing: 0.02em !important;
	padding: 12px !important;
	border-radius: 8px !important;
	min-width: 220px !important;
	width: 100%;
	transition: 0.2s all ease;
}

.menu_item:hover {
	color: #ffffff !important;
	background: #012c63 !important;
}

menu_item:focus {
	color: #afafaf !important;
	background: #ffffff !important;
}

.bulk_deposit_header {
	padding: 14px 16px;
	background-color: #ffffff;
	border-radius: 12px;
	height: 73px;
	display: flex;
	align-items: center;
}

.bulk_deposit_header_text {
	font-family: "Montserrat";
	font-style: normal;
	letter-spacing: 0.02em;
	color: #000000;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
}

.bulk_deposit_container {
	display: inline-block;
	width: 100%;
	padding: 30px;
}

.bulk_deposit_card {
	padding: 20px 20px 20px 35px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background: #ffffff;
	border-radius: 8px;
	margin-top: 20px;
}

.bulk_deposit_heading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 27px;
	letter-spacing: 0.02em;
}

.bulk_deposit_label {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #000000;
}

.bulk_deposit_label > span {
	color: #ee6161;
}

.download_template_button {
	padding: 10px 10px;
	background: #ffe4cb;
	border-radius: 50px;
	outline: none;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 1;
	letter-spacing: 0.02em;
	color: #000000;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bulk_deposit_file_name {
	padding-left: 6px;
}

.bulk_deposit_buttons {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.bulk_deposit_file {
	height: 60px;
}

.bulk_deposit_edit_text {
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #012c63;
	cursor: pointer;
}

.bulk_deposit_file_div {
	display: flex;
	align-items: center;
	gap: 10px;
}

.download_template_icon {
	fill: #000;
}

.button_row_add_mpp {
	display: flex;
	justify-content: space-between;
	/* padding: 0px 140px 3rem 140px; */
}

@media screen and (max-width: 768px) {
	.add_lead_header {
		flex-direction: column;
		align-items: flex-start !important;
		gap: 15px;
	}
	.containerWhite {
		margin: 10px 10px 10px 10px;
		padding: 8px 8px 8px 8px;
	}

	.button_row_add_mpp {
		margin-top: 20px;
		padding: 0px 30px;
	}

	.addMppContainer {
		padding-inline: 20px !important;
	}

	.filesBox.add_mpp {
		width: 100%;
	}

	.footer .footer-attendance {
		width: 100%;
		height: 90px;
	}

	.student_search_input.add_mpp {
		padding: 1px 1px 1px 15px;
	}
}

.button_row_add_mpp_alert {
	display: flex;
	justify-content: flex-end;
	padding: 0px 10px;
	gap: 20px;
}

.addMppContainer {
	background-color: #ffffff;
	padding: 15px;
	border-radius: 8px;
	gap: 24px;
	margin-top: 15px;
	padding-inline: 36px;
	align-items: center;
}
.addQBContainer {
	background-color: #ffffff;
	padding: 15px;
	border-radius: 8px;
	gap: 24px;
	margin-top: 15px;
	padding-inline: 20px;
	align-items: center;
}

.mpp_option {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #0d0c0c;
}

.mpp_option:hover {
	font-weight: 500;
}

.mpp_instruction_header1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	color: #101010;
}

.mpp_instruction_header2 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
}

.mpp_instruction_container {
	border: 1px solid #d7d7d7;
	border-radius: 8px;
	padding: 20px;
	gap: 17px;
	display: flex;
	flex-direction: column;
}

.mpp_instruction_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #000000;
}

.mpp_text_container {
	display: flex;
	flex-direction: row;
	gap: 15px;
}

.mpp_tag {
	background: #ececec;
	border-radius: 8px;
	padding: 10px;
	align-items: center;
	justify-content: center;
	width: fit-content;
}

.mpp_tag_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
}

.mpp_text_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
}

.mpp_file_upload_container1 {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	/* gap: 10; */
}

.mpp_file_upload_container2 {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 90;
}

.mpp_gray_container {
	gap: 10;
	background-color: #f9f9f9;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	align-items: flex-start;
	padding: 10px 20px;
	margin-top: 7px;
	margin-right: 10px;
	min-width: 218px;
}

.mpp_gray_container_text1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #515151;
}

.mpp_gray_container_text2 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #000000;
}

.mpp_error_description_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #929292;
}

.mpp_review_header {
	font-size: 1.3em;
	letter-spacing: 0.015em;
	color: #012c63;
}

.mpp_review_tag {
	background: rgba(255, 255, 255, 0.15);
	font-size: 0.8em;
	border: 0.7px solid #cdcdcd;
	border-radius: 2px;
	padding: 0px 6px;
	color: #e97a26;
	letter-spacing: 0.015em;
	font-weight: bold;
	width: fit-content;
}

.mpp_section {
	background: rgba(255, 255, 255, 0.15);
	box-shadow: 0.5px 0.5px 4px 2px #00000026;
	margin-right: 10px;
	margin-left: 5px;
	font-weight: bold;
	font-size: 1.1em;
	letter-spacing: 0.015em;
	color: #012c63;
	padding: 5px 25px;
	width: fit-content;
}

.boxed {
	display: flex;
	padding: 1%;
	border: solid 1px #ccc;
	transition: all 0.3s;
	border-radius: 0.5em;
	/* margin: 10px auto; */
	font-weight: 500;
}

.mpp_qp_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	color: #393939;
}

.mpp_qp_title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	text-align: center;
	color: #000000;
	text-align: left;
}

.mpp_qp_content {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 18px;
	text-align: center;
	color: #929292;
	text-align: left;
}

.mpp_qp_small_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #000000;
}

.mpp_qp_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
	margin-top: 20px;
}

.mpp_qp_tag {
	align-items: center;
	padding: 4px 8px;
	gap: 2px;
	height: 32px;
	background: #f1f1f1;
	border-radius: 40px;
	width: fit-content;
}

.mpp_qp_tag_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	align-items: center;
	text-align: center;
	color: #6f6e6e;
}

.mpp_difficulty_level_unselected {
	width: 24.55px;
	height: 24.55px;
	border: 0.7px solid #bfbfbf;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mpp_difficulty_level_selected {
	width: 28px;
	height: 28px;
	border: 0.7px solid #1b3687;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mpp_difficulty_level_text_unselected {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 19.6364px;
	color: #bfbfbf;
}

.mpp_difficulty_level_text_selected {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 24.5455px;
	color: #1b3687;
}

.mpp_review_container1 {
	width: 100%;
	padding: 10px 20px;
	gap: 20px;
}

.mpp_review_container2 {
	display: flex;
	flex-direction: row;
	width: 100%;
	/* padding: 30px 60px; */
	gap: 20px;
}

.mpp_previous_button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 13px 35px;
	gap: 10px;
	width: 135px;
	height: 44px;
	background: #eaf3ff;
	border-radius: 10px;
}

.bg_white {
	background: white !important;
}

.mpp_footer {
	/* position: fixed; */
	bottom: 0;
	display: flex;
	flex: 1;
	background-color: #ffffff;
	justify-content: space-between;
	box-shadow: 0px -10px 50px rgba(0, 0, 0, 0.07);
	padding-right: 50;
	align-items: center;
	padding-left: 20;
}

.mpp_scroll_container {
	height: calc(100vh - 48.7rem);
	overflow: auto;
	margin-right: -0.8rem;
	margin-top: 10px;
	padding-bottom: 10px;
	padding-top: 0px;
	padding-right: 0.8rem !important;
}

.mpp_scroll_container.big {
	height: calc(100vh - 43.5rem);
}

.mpp_divider1 {
	flex: 0.08;
}

.mpp_divider2 {
	flex: 0.13;
	min-width: 80px;
}

.mpp_divider3 {
	flex: 0.25;
	min-width: 100px;
}

.mpp_divider4 {
	flex: 0.37;
	min-width: 200px;
}

.mpp_assign_button {
	background-color: #012c63;
	font-weight: 600;
	font-style: normal;
	padding-left: 35px;
	padding-right: 35px;
	/* width: 89px; */
	font-family: "Montserrat";
	outline: none !important;
	color: #ffffff !important;
	height: 50px;
	border-radius: 10px;
	/* margin-top: -0.3rem; */
	cursor: pointer;
	min-width: 100px;
}

.mpp_assign_back {
	align-items: center;
	font-weight: 600;
	font-style: normal;
	padding-left: 35px;
	padding-right: 35px;
	font-family: "Montserrat";
	outline: none !important;
	height: 50px;
	border-radius: 10px;
	margin-left: 1.5rem;
	cursor: pointer;
	min-width: 100px;
	background-color: #eff6ff;
	color: #181717;
}

.mpp_count {
	padding: 8px 30px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #100f0f;
	text-align: center;
	background: #f9f9f9;
	border: 1px solid #e2e2e2;
	border-radius: 24px;
}

.mpp_batch {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	margin-right: 8px;
	margin-top: 6px;
	gap: 4px;
	background: #f9f9f9;
	border: 1px solid #e2e2e2;
	border-radius: 24px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 21px;
}

.mpp_batch_tag {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	margin-right: 8px;
	margin-top: 6px;
	gap: 4px;
	border-radius: 24px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 21px;
	background: #e7e7e7;
	color: #100f0f;
}

.bottom-assign-batch {
	position: sticky;
	bottom: 0;
	background-color: #ffffff;
	padding: 25px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.mpp_modal_container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	background-color: #ffffff;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	width: 70%;
}

.view_mpp_modal {
	box-sizing: 24;
	padding: 20px;
	border-radius: 5px;
	overflow: auto;
	max-height: 90vh;
}

.mpp_details_card {
	align-items: flex-start;
	justify-content: center;
	padding: 11px 15px;
	gap: 5px;
	margin-right: 20px;
	margin-bottom: 10px;
	background: #f9f9f9;
	border-radius: 10px;
	width: 100%;
	max-width: 275px;
}

.mpp_details_card_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.02em;
	color: #515151;
}

.mpp_details_card_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 18px;
	letter-spacing: 0.012em;
	color: #000000;
	width: 100%;
}

.mpp_batch_card {
	background: #ffffff;
	box-shadow: 8px 8px 50px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.mpp_search_input {
	border: 1px solid #858585;
	height: 40px;
	border-radius: 8px;
	min-width: 250px;
	width: 100%;
	padding: 3px 3px 3px 10px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #000000;
	background-color: #ffffff;
	outline: none;
}

.mpp_bottom_border {
	display: flex;
	width: 100%;
	padding: 11px 100px 11px 10px;
	border-bottom: 1.2px solid #f2f2f2;
	align-items: flex-start;
	gap: 10px;
}

.mpp_card_height {
	padding: 1.5rem;
	background-color: #ffffff;
	border-radius: 12px;
	display: flex;
	align-items: center;
	max-height: calc(100vh - 68rem) !important;
}

.mpp_assign_container {
	background-color: #ffffff;
	margin-right: -0.8rem;
	margin-top: 20px;
	padding: 0.7rem 1.5rem 0.5rem 1.5rem;
	border-radius: 12px;
	height: calc(100vh - 44rem);
}

.mpp_assign_scroll_container {
	height: calc(100vh - 52rem);
	overflow: auto;
	margin-right: -15px;
}

.mpp_assign_scroll_container.big {
	height: calc(100vh - 45rem);
}

.mpp_batch_scroll_container {
	max-height: 22vh;
	background-color: #ffffff;
	overflow: auto;
}

.mpp_view_header {
	padding: 10px 20px 0px 20px;
}

.mpp_grey_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	/* identical to box height, or 112% */

	letter-spacing: 0.02em;

	color: #515151;
}

.mpp_grey_header.smallText {
	font-size: 13px !important;
	line-height: 22px;
}

.mpp_assign_card {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	/* flex-direction: column; */
	padding: 12px 20px;
	gap: 20px;
	background: #f9f9f9;
	border-radius: 8px;
	margin-top: 1.2rem;
	flex-wrap: wrap;
	width: 100%;
}

.mpp_assign_card.width99 {
	width: calc(100% - 15px) !important;
}

@media only screen and (max-width: 1160px) {
	.mpp_modal_container {
		width: 85%;
	}
}

@media only screen and (max-width: 750px) {
	.mpp_details_card {
		max-width: 100%;
		margin-right: 0px;
	}
}

.reversal_student_id {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: #8d8d8d;
}

.reversal_student_id_padding {
	padding-top: 0.5rem !important;
}

.student_reversal_fee_tag {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5px 12px;
	gap: 10px;
	background: #f9d7e5;
	border-radius: 76px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: right;
	letter-spacing: 0.02em;
	color: #000000;
}
.student_reversal_fee_tag.advance {
	background: rgba(215, 249, 218, 1);
}

.qb_add_header {
	padding: 20px 10px 20px 10px;
	background-color: #ffffff;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2rem;
}

.lead_search_container {
	position: relative;
	width: 100%;
	max-width: 330px;
}

.lead_search_box {
	width: 100%;
}

@media only screen and (max-width: 990px) {
	.lead_search_container {
		max-width: auto;
	}
}

.p-relative {
	position: relative !important;
}

.course_online_offering_limit {
	position: absolute;
	right: 4.8rem;
	width: 0px;
}

.course_online_image_wrapper {
	width: 100%;
	padding: 15px;
	height: 160px !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.course_online_image {
	max-height: 150px;
	max-width: 90%;
}

.course_online_image_edit {
	margin-left: 3rem;
	font-weight: 500;
	font-size: 1.5rem;
}

.test_chapters_more {
	background: #f1f5ff;
	border: 1px solid #b5c8ff;
	border-radius: 74px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: #1f3061;
}

label.contain.active {
	background: #ffe8d7;
	border: 1px solid #e97a26;
}

/* Hide the browser's default radio button */
.paymentPopup .contain input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.paymentPopup .checkmark {
	position: absolute;
	top: 8px;
	left: 6px;
	height: 14px;
	width: 14px;
	background-color: #fff;
	border-radius: 50%;
	border: 1px solid #000;
}

/* On mouse-over, add a grey background color */
.paymentPopup .contain:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.paymentPopup .contain input:checked ~ .checkmark {
	background-color: transparent;
	border: 1px solid #000;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.paymentPopup .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.paymentPopup .contain input:checked ~ .checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.paymentPopup .contain .checkmark:after {
	top: 2px;
	left: 2px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: black;
}

.mywalletContainer {
	padding: 4px 10px;
	background: #f7f7f7;
	border-radius: 8px;
	display: flex;
	align-items: center;
	font-weight: 500;
	gap: 5px;
	padding-left: 0px;
	font-size: 12px;
	line-height: 18px;
}

.paymentMywalletRedeem {
	font-weight: 500;
	font-size: 16px;
	margin-top: 10px;
	color: #0057bc;
	cursor: pointer;
	letter-spacing: 0.02em;
}
.paymentMywalletRedeem.disabled {
	cursor: not-allowed !important;
}

.card_wallet_container {
	width: 100%;
	background: #ffffff;
	border: 1px solid #eeeeee;
	border-radius: 8px;
	margin-block-end: 20px;
}

.myWalletDiv {
	display: flex !important;
	align-items: center;
	gap: 10px;
	flex: 0.76 !important;
	max-width: 270px;
	min-width: 210px;
}

.successTagWallet {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	gap: 10px;

	background: #e8ffe0;
	border-radius: 35px;

	width: max-content;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.02em;

	color: #35cb00;
}

.failedTagWallet {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	gap: 10px;

	background: #ffeeee;
	border-radius: 35px;

	width: max-content;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.02em;

	color: #9b0000;
}

.pendingTagWallet {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	gap: 10px;

	background: #fcefb4;
	border-radius: 35px;

	width: max-content;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.02em;

	color: #ffa200;
}
.reversedTagWallet {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	gap: 10px;

	background: #e0e5ff;
	border-radius: 35px;

	width: max-content;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.02em;

	color: #012c63;
}

.smallTextWallet {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 20px;
	color: #8d8d8d;
}

.mywallet-container-edit {
	background: #f7f7f7;
	border-radius: 8px;
	padding: 4px 6px;
	gap: 5px;
	cursor: pointer;
}

@media (max-width: 850px) {
	.myWalletDiv {
		flex: unset !important;
	}
}
