.progress_line1 {
	flex: 0.5 1;
	height: 4px;
	background-color: #e97a26;
}

.progress_line2 {
	flex: 0.5 1;
	height: 2px;
	background-color: #012c63;
}

.steps-container {
	position: relative;
	padding: 0 40px;
	margin-top: -26px;
	user-select: none;
}

.step-number {
	padding: 10px;
	width: 48px;
	height: 48px;
	background: #012c63;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 27px;
	letter-spacing: 0.02em;
	color: #ffffff;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.step-label {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: 0.02em;
	color: #000000;
	margin-top: 1.2rem;
	text-align: center;
}

.active-step-ring {
	border: 2px solid #e97a26;
	width: 64px;
	height: 64px;
	border-radius: 100%;
	background-color: #ffffff;
	position: absolute;
	left: 50%;
	top: -8px;
	translate: -50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
