@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

/* Universal Color Coding */
.blue-primary {
    color: #012C63;
}


/* Bootstrap margin and padding */
.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.m-1 {
    margin: 0.5em !important;
}

.mt-1 {
    margin-top: 0.5em !important;
}

.my-1 {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.mx-1 {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
}

.mb-1 {
    margin-bottom: 0.5em !important;
}

.ml-1 {
    margin-left: 0.5em !important;
}

.mr-1 {
    margin-right: 0.5em !important;
}

.m-2 {
    margin: 0.8em !important;
}

.mt-2 {
    margin-top: 0.8em !important;
}

.my-2 {
    margin-top: 0.8em !important;
    margin-bottom: 0.8em !important;
}

.mx-2 {
    margin-left: 0.8em !important;
    margin-right: 0.8em !important;
}

.mb-2 {
    margin-bottom: 0.8em !important;
}

.ml-2 {
    margin-left: 0.8em !important;
}

.mr-2 {
    margin-right: 0.8em !important;
}

.m-3 {
    margin: 1.25em !important;
}

.mt-3 {
    margin-top: 1.25em !important;
}

.my-3 {
    margin-top: 1.25em !important;
    margin-bottom: 1.25em !important;
}

.mx-3 {
    margin-left: 1.25em !important;
    margin-right: 1.25em !important;
}

.mb-3 {
    margin-bottom: 1.25em !important;
}

.ml-3 {
    margin-left: 1.25em !important;
}

.mr-3 {
    margin-right: 1.25em !important;
}

.m-4 {
    margin: 1.75em !important;
}

.mt-4 {
    margin-top: 1.75em !important;
}

.my-4 {
    margin-top: 1.75em !important;
    margin-bottom: 1.75em !important;
}

.mx-4 {
    margin-left: 1.75em !important;
    margin-right: 1.75em !important;
}

.mb-4 {
    margin-bottom: 1.75em !important;
}

.ml-4 {
    margin-left: 1.75em !important;
}

.mr-4 {
    margin-right: 1.75em !important;
}

.m-5 {
    margin: 2.1em !important;
}

.mt-5 {
    margin-top: 2.1em !important;
}

.my-5 {
    margin-top: 2.1em !important;
    margin-bottom: 2.1em !important;
}

.mx-5 {
    margin-left: 2.1em !important;
    margin-right: 2.1em !important;
}

.mb-5 {
    margin-bottom: 2.1em !important;
}

.ml-5 {
    margin-left: 2.1em !important;
}

.mr-5 {
    margin-right: 2.1em !important;
}



.p-0 {
    padding: 0em !important;
}

.pt-0 {
    padding-top: 0em !important;
}

.py-0 {
    padding-top: 0em !important;
    padding-bottom: 0em !important;
}

.px-0 {
    padding-left: 0em !important;
    padding-right: 0em !important;
}

.pb-0 {
    padding-bottom: 0em !important;
}

.pl-0 {
    padding-left: 0em !important;
}

.pr-0 {
    padding-right: 0em !important;
}


.p-1 {
    padding: 0.5em !important;
}

.pt-1 {
    padding-top: 0.5em !important;
}

.py-1 {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
}

.px-1 {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
}

.pb-1 {
    padding-bottom: 0.5em !important;
}

.pl-1 {
    padding-left: 0.5em !important;
}

.pr-1 {
    padding-right: 0.5em !important;
}

.p-2 {
    padding: 0.8em !important;
}

.pt-2 {
    padding-top: 0.8em !important;
}

.py-2 {
    padding-top: 0.8em !important;
    padding-bottom: 0.8em !important;
}

.px-2 {
    padding-left: 0.8em !important;
    padding-right: 0.8em !important;
}

.pb-2 {
    padding-bottom: 0.8em !important;
}

.pl-2 {
    padding-left: 0.8em !important;
}

.pr-2 {
    padding-right: 0.8em !important;
}

.p-3 {
    padding: 1.25em !important;
}

.pt-3 {
    padding-top: 1.25em !important;
}

.py-3 {
    padding-top: 1.25em !important;
    padding-bottom: 1.25em !important;
}

.px-3 {
    padding-left: 1.25em !important;
    padding-right: 1.25em !important;
}

.pb-3 {
    padding-bottom: 1.25em !important;
}

.pl-3 {
    padding-left: 1.25em !important;
}

.pr-3 {
    padding-right: 1.25em !important;
}

.p-4 {
    padding: 1.75em !important;
}

.pt-4 {
    padding-top: 1.75em !important;
}

.py-4 {
    padding-top: 1.75em !important;
    padding-bottom: 1.75em !important;
}

.px-4 {
    padding-left: 1.75em !important;
    padding-right: 1.75em !important;
}

.pb-4 {
    padding-bottom: 1.75em !important;
}

.pl-4 {
    padding-left: 1.75em !important;
}

.pr-4 {
    padding-right: 1.75em !important;
}

.p-5 {
    padding: 2.1em !important;
}

.pt-5 {
    padding-top: 2.1em !important;
}

.py-5 {
    padding-top: 2.1em !important;
    padding-bottom: 2.1em !important;
}

.px-5 {
    padding-left: 2.1em !important;
    padding-right: 2.1em !important;
}

.pb-5 {
    padding-bottom: 2.1em !important;
}

.pl-5 {
    padding-left: 2.1em !important;
}

.pr-5 {
    padding-right: 2.1em !important;
}

/* Bootstrap margin padding end */

.main-cont {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
    margin-right: 0 !important;
}

.img-side {
    background: url(../../../assets/img/landing-side-img.jpeg);
    background-color: #1e3160;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
}

.form-side {
    margin-top: 3em;
    padding: 3em 4em;
}

.mob-gp {
    margin-bottom: 1.5em;
}

.formm {
    margin-top: 2.5em;
}

.row-check {
    display: flex;
    justify-content: space-between;
}

.login-text {
    font-weight: 600;
    margin-top: 2em;
    margin-bottom: 1em;
}

.logo-img {
    width: 45%;
}

.form-label {
    color: #000000 !important;
    font-weight: 500;
}

.login-form-input-f {
    font-size: 1.2em;
    border-radius: 8px;
    border: 1px solid #858585;
}

.login-btn,
.login-btn:hover,
.login-btn:focus,
.login-btn:visited {
    min-width: 50%;
    color: #ffffff;
    background-color: #012C63;
    font-size: 18px;
}

.student-line {
    color: #6C6C6C;
}

.student-link,
.student-link:hover,
.student-link:focus,
.student-link:visited {
    color: #6C6C6C;
    font-weight: 600;
}

.forgot-pass:hover {
    cursor: pointer;
}


/* .modal-content {
    background-color: #E1FFD7;
    color: #107100;
} */

.success-chip {
    width: 40%;
}

/* Square Laptop  */
@media (max-width: 993px) {
    .img-side {
        display: none;
    }

    .login-btn,
    .login-btn:hover,
    .login-btn:focus,
    .login-btn:visited {
        width: 100%;
    }

    .main-cont {
        --bs-gutter-x: 0 !important;
        --bs-gutter-y: 0 !important;
        margin-right: -15px !important;
    }
}

/* Tablet Media  */
@media (max-width: 767.98px) {
    .img-side {
        display: none;
    }

    .login-btn,
    .login-btn:hover,
    .login-btn:focus,
    .login-btn:visited {
        width: 100%;
    }

    .main-cont {
        --bs-gutter-x: 0 !important;
        --bs-gutter-y: 0 !important;
        margin-right: -15px !important;
    }
}

/* Phone Media  */
@media (max-width: 575.98px) {
    .img-side {
        display: none;
    }


    .login-btn,
    .login-btn:hover,
    .login-btn:focus,
    .login-btn:visited {
        width: 100%;

    }

    .main-cont {
        --bs-gutter-x: 0 !important;
        --bs-gutter-y: 0 !important;
        margin-right: -15px !important;
    }
}