.hostelWrap {
    display: flex;
    justify-content: flex-end;
}
.hostelWrap a {
    position: relative;
    top: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #000000;
}
.hostelWrap a:hover {
    text-decoration: none;
}
.hostelWrap a img {
    margin-right: 5px;
    position: relative;
    top: -3px;
}
.DateWrap input[type="date"] {
    width: 100%;
    height: 38px;
    border-radius: 6px;
    border: 1px solid #858585;
    padding: 10px;
}
.DateWrap input[type="date"]:focus {
    outline: none;
}
