.student_details_wrapper {
  &.is-mobile {
    padding: 0 20px;
    .student_details_container {
      padding: 16px 20px 18px 20px;
      min-height: auto;
    }
  }
}

.student_add_container {
  @media screen and (max-width: 800px) {
    padding-top: 5rem;
  }
}

.student_details_edit_container {
  .student_details_edit_form {
    width: 100%;
    max-width: 350px;
  }
}

@media screen and (max-width: 800px) {
  .containerMain.edit-student {
    .student_details_edit_header_container,
    .student_details_edit_container,
    .student_details_edit_save {
      max-width: 410px;
      margin: 0 auto;
    }

    .student_details_edit_header_container {
      margin-top: 2rem;
    }
  }
}
