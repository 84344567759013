.payment_reversal_table-container {
  display: flex;
  gap: 14px;
  align-items: flex-start;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    row-gap: 14px;
  }
}

.payment-reversal-header {
  font-weight: 500;
  font-size: 1.65rem;
}

.payment-reversal-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 14px;
}

.reversal_reason_text {
  margin-top: 0;
}

.payment-reversal-card {
  display: flex;
  width: 100%;
  gap: 6px;

  &.spaced {
    gap: 16px;
  }

  &.even {
    gap: 10px;
  }

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    gap: 14px;
  }

  @media screen and (min-width: 601px) {
    justify-content: space-between;
  }

  .payment-rc {
    &-header {
      font-size: 1.5rem;

    }

    &-text {
      font-weight: 600;
      font-size: 1.375rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .payment_reversal_collapse_container {
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;

    .payment_reversal_collapse_button {
      width: auto;

      &-text {
        margin-right: 6px;
      }
    }
  }
}

@media screen and (min-width: 601px) {
  .payment_reversal_collapse_button-text {
    display: none;
  }
}