* {
  font-family: "Montserrat", sans-serif;
}
/*@page {
  size: "A4";
}*/
.blue-box {
  background-color: #012c63;
  padding-top: 1%;
  padding-bottom: 1%;
  margin:  0 ;
}

.ack-text {
  color: #ffffff;
  font-weight: 600;
  size: 24.9px;
  line-height: 31px;
  letter-spacing: 0.025em;
}

.rect-img-cont {
  width:67%;
  height: 300px;
  z-index: 99;
  position: absolute;
  top: -45px;
  left: 45px;
}

.partner-logo-img{
  width: 48%;
  position: absolute;
  left: 85px;
  top: 40px;
  z-index: 99999999;
}

.ack-below-box {
  background-color: #fff8f3;
  padding-top: 10px;
  padding-bottom: 10px;
  display:  flex;
  width:  100%;
}

.company-deets {
  padding-left: 14%;
  font-size: 13px;
  letter-spacing: 0.02em;
  /* width: 60% !important; */
  margin-left: 200px;
}

.small-line {
  font-size: 10px;
  font-weight: 500;
}

.address {
  font-size: 12px;
  width: 60%;
  font-weight: 500;
}

.cin {
  font-weight: 600;
}

.myclass-center {
  font-size: 13px;
  padding-left: 30px;
}

.maincontent {
  margin-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

.acknowledgement-num {
  display: flex;
  justify-content: space-around;
}

.stud-details-box {
  margin-top: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 50px;
  width: 900px;
}

.stud-deet-td {
  text-align:  left;
  padding-right: 3px;
  padding-left: 33px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pay-deets-1-tab {
  width: 800px;
  margin-bottom: 8px;
}

.pay-deets-1-tab td {
  width: 400px;
  border: 1px solid #2A3885;
  text-align: center;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pay-deets-2-tab {
  width: 800px;
}

.pay-deets-2-tab td {
  width: 400px;
  border: 1px solid #2A3885;
  text-align: center;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #FFF8F3;
}

.to-view{
  font-size: 13px;
  margin-top: 13px;
  /*margin-left: 50px;*/
}

.information{
  font-size: 15px;
  margin-top: 50px;
  /*margin-left: 50px;*/
}

.comp-gen{
  margin-top: 80px;
  margin-bottom: 20px;
}

.below-img{
  width: 100%;
}