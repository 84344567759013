.installment-list-cards {
  margin-top: 1.5rem;
  width: 100%;
}

.installment-card {
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.175);
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.installment-card:not(:last-child) {
  margin-bottom: 1rem;
}

.installment-name {
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;
  color: #222222;
  font-size: 1.75rem;
}

.installment-name:not(.with-due) {
  padding-right: 2rem;
}

.installment-name.with-due {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.installment-name.with-due .installment-status {
  width: 14px;
  flex: 0 0 14px;
}

.installment-name.with-due .installment-name-text {
  width: calc(60% - 14px);
  flex: 1 0 calc(60% - 14px);
  font-size: 16px;
  margin-top: 2px;
}

.installment-name.with-due .installment-date {
  width: 40%;
  text-align: right;
}

.installment-delete {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  height: 3rem;
  width: 3rem;
}

.installment-delete button {
  border: 1px solid #8b8b8b;
  box-sizing: border-box;
  border-radius: 4px;
  color: #8b8b8b;
  background: #fff;
  transition: 0.5s all ease;
}

.installment-delete button:hover {
  color: #ff0000;
  border-color: #ff0000;
}

.installment-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  column-gap: 1rem;
  flex-wrap: nowrap;
}

.installment-options .installment-date,
.installment-options .installment-amount {
  width: 50%;
}

.installment-options.with-due .installment-date,
.installment-options.with-due .installment-amount,
.installment-options.with-due .installment-due {
  width: 50%;
}

.installment-card .installment-label {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1;
}

.installment-options .installment-date input,
.installment-options .installment-amount input {
  width: 100%;
  position: relative;
  height: 32px;
  text-align: left;
  border: 0;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  border: none;
  padding: 14px;
}

.installment-options .installment-date label,
.installment-options .installment-amount label {
  font-weight: normal;
}

.installment-options .installment-amount .currencyWrap input {
  padding-left: 40px;
}

@media screen and (max-width: 600px) {
  .installment-options .installment-amount .currencyWrap input {
    padding-left: 25px;
  }
}

.installment-options .installment-amount .currencyWrap > span {
  position: absolute;
  left: 12px;
  transform: translateY(-50%);
  top: 50%;
}

@media screen and (max-width: 1198px) {
  .modal.fade.installmentPlanWrap .modal-dialog {
    margin-top: 80px;
  }

  .modal.fade.installmentPlanWrap .modal-dialog .modal-body {
    padding: 20px 40px;
  }

  .modal.fade.installmentPlanWrap table.table thead tr th:last-child,
  .modal.fade.installmentPlanWrap table.table tbody tr td:last-child {
    text-align: right;
  }
}

@media screen and (max-width: 900px) {
  .modal.fade.installmentPlanWrap .modal-dialog {
    margin-top: 60px;
  }

  .modal.fade.installmentPlanWrap .modal-dialog .modal-body {
    padding: 20px;
  }

  .modal.fade.installmentPlanWrap .modal-dialog h2 {
    font-size: 2.5rem;
  }

  .modal.fade.installmentPlanWrap .modal-dialog h3 {
    font-size: 2.175rem;
  }

  .modal.fade.installmentPlanWrap table.table thead tr th,
  .modal.fade.installmentPlanWrap table.table tbody tr td {
    font-size: 1.5rem;
    padding: 1.25rem;
  }

  .installmentPlanWrap .tableWrap tfoot td {
    font-size: 1.5rem;
  }

  .installment-options .installment-date .installment-label,
  .installment-options .installment-amount .installment-label,
  .installment-name.with-due .installment-label,
  .installment-card .installment-label {
    font-size: 15px;
    font-weight: 400;
  }

  .installment-name.with-due .installment-text,
  .installment-options .installment-text {
    font-size: 16px;
    font-weight: 500;
  }

  .payment-summary .installmentList-buttons button.payNowBtn,
  .payment-summary .installmentList-buttons button.payhistBtn {
    font-size: 1.5rem;
    padding: 4px 12px;
    line-height: 2;
  }
}

@media screen and (max-width: 750px) {
  .installment-options .installment-date .installment-label,
  .installment-options .installment-amount .installment-label,
  .installment-name.with-due .installment-label,
  .installment-card .installment-label {
    font-size: 14px;
    font-weight: 400;
  }

  .installment-name.with-due .installment-text,
  .installment-options .installment-text {
    font-size: 15px;
    font-weight: 500;
  }

  .payment-summary .installmentList-buttons button.payNowBtn,
  .payment-summary .installmentList-buttons button.payhistBtn {
    font-size: 1.35rem;
    padding: 4px 12px;
    line-height: 2;
  }
}

@media screen and (max-width: 600px) {
  .modal.fade.installmentPlanWrap .modal-dialog {
    margin-top: 40px;
  }

  .modal.fade.installmentPlanWrap {
    width: 90%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .modal.fade.installmentPlanWrap .modal-dialog h2 {
    font-size: 2.125rem;
  }

  .modal.fade.installmentPlanWrap .modal-dialog h3 {
    font-size: 1.75rem;
  }

  .modal.fade.installmentPlanWrap table.table thead tr th,
  .modal.fade.installmentPlanWrap table.table tbody tr td {
    font-size: 1.35rem;
    padding: 1.25rem 0.75rem;
  }

  .modal.fade.installmentPlanWrap .btn.modal-button.cancelBtn,
  .modal.fade.installmentPlanWrap button.btn.btn-primary.modal-button.confirmBtn2 {
    width: auto;
    height: auto;
    font-size: 1.25rem;
  }

  .installment-options .installment-date .installment-label,
  .installment-options .installment-amount .installment-label,
  .installment-name.with-due .installment-label,
  .installment-card .installment-label {
    font-size: 13px;
    font-weight: 400;
  }

  .installment-name.with-due .installment-text,
  .installment-options .installment-text {
    font-size: 13px;
    font-weight: 500;
  }

  .payment-summary .installmentList-buttons button.payNowBtn,
  .payment-summary .installmentList-buttons button.payhistBtn {
    font-size: 1.25rem;
    padding: 4px 12px;
    line-height: 2;
  }
}
